import React from "react";
import dayjs from "dayjs";
import { RemoveCircleOutlineOutlined } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";

import { useTenant } from "hooks/settings";

import {
  Crane,
  LoadBasketRead,
  LoadRead,
  useDeleteLoadMutation,
  useGetDeployedPlanMixesQuery,
  useListCranesQuery,
  useListLoadsQuery,
} from "src/store/api/generatedApi";
import { TitledTabs } from "components/common/titledTabs";
import { useTenantTranslation } from "hooks/formatters";
import { CraneChip } from "./CraneChip";
import {
  getLoadVariant,
  isActive,
  isComplete,
  isPending,
  refreshMS,
} from "components/crane/utils.ts";

import { LoadModal } from "./LoadModal";
import { initialDeploymentTypeToDeploymentTypes } from "./SupervisorView";

type LoadVariant = "backup" | "out_of_date" | "new" | "next";

type BaseLoadProps = {
  name: string;
  mixId: number;
  loadId: number;
  copperGroupWeightPercentMax: number;
  onClick: () => void;
  variant: LoadVariant;
};

type ActiveLoadProps = BaseLoadProps & { cranes: Crane[] };

type LoadProps = BaseLoadProps | ActiveLoadProps;

type BaseLoadRowProps = {
  loadId: number;
  onClick: () => void;
  name: string;
  mixId: number;
  copperGroupWeightPercentMax: number;
  variant: LoadVariant;
};

type ActiveLoadRowProps = {
  cranes: Crane[];
} & BaseLoadRowProps;

type LoadRowProps = BaseLoadRowProps | ActiveLoadRowProps;

type QueueProps = {
  activeLoads: LoadRead[];
  pendingLoads: LoadRead[];
  onLoadClick: (id: number) => void;
  unassignedCranes: Crane[];
  currentPlanId: number;
  backupPlanId: number;
  nextPlanId: number | null;
};

type CompletedProps = {
  loads: LoadRead[];
  onLoadClick: (id: number) => void;
  currentPlanId: number;
  backupPlanId: number;
  nextPlanId: number | null;
};

type TabbedBodyProps = {
  loads: LoadRead[];
  cranes: Crane[];
  currentPlanId: number;
  backupPlanId: number;
  nextPlanId: number | null;
};

const Load = ({
  name,
  mixId,
  copperGroupWeightPercentMax,
  onClick,
  variant,
  ...props
}: LoadProps) => {
  const theme = useTheme();
  const { t } = useTenantTranslation();

  const hasCranes = "cranes" in props;

  return (
    <Box
      onClick={onClick}
      sx={{
        boxShadow: theme.shadows[1],
        borderColor: theme.palette.divider,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 1,
        paddingTop: 1,
        paddingBottom: hasCranes ? 1.5 : 1,
        paddingX: 2,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        gap: 1,
        cursor: "pointer",
        backgroundColor: theme.palette.common.white,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
          {name}&nbsp;
        </Typography>
        <Typography
          variant="body2"
          component="span"
          sx={{
            color: theme.palette.data.orange,
            borderRadius: 2,
            paddingY: "1px",
            paddingX: "2px",
          }}
        >
          Cu {(copperGroupWeightPercentMax * 100).toFixed(0)}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.text.secondary,
            paddingY: 0.5,
          }}
        >
          {mixId}
        </Typography>
        {((): Exclude<React.ReactNode, undefined> => {
          switch (variant) {
            case "next":
            case "backup":
              return (
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.info.main,
                    borderRadius: 2,
                    paddingY: 0.5,
                    paddingX: 1,
                  }}
                >
                  {t(variant)}
                </Typography>
              );
            case "out_of_date":
              return (
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.common.white,
                    borderRadius: 2,
                    backgroundColor: theme.palette.warning.main,
                    paddingY: 0.5,
                    paddingX: 1,
                  }}
                >
                  {t("outOfDate")}
                </Typography>
              );
            case "new":
              return null;
          }
        })()}
      </Box>
      {hasCranes ? (
        <Box
          sx={{
            display: "flex",
            alignContent: "flex-start",
            gridColumn: "1",
            gap: 1,
          }}
        >
          {props.cranes.map((crane) => (
            <CraneChip
              key={crane.user_uid}
              name={crane.name}
              variant="success"
            />
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export const LoadQueue = () => {
  const tenantName = useTenant();

  const createdSince = React.useMemo(() => {
    return dayjs().subtract(1, "day").toISOString();
  }, []);

  const activePlanMixes = useGetDeployedPlanMixesQuery({
    tenantName,
    planId: "latest",
    period: 1,
    deploymentTypes: initialDeploymentTypeToDeploymentTypes.standard,
  });

  const nextActivePlanMixes = useGetDeployedPlanMixesQuery({
    tenantName,
    planId: "next",
    period: 1,
    deploymentTypes: initialDeploymentTypeToDeploymentTypes.standard,
  });

  const backupPlanMixes = useGetDeployedPlanMixesQuery({
    tenantName,
    planId: "latest",
    period: 1,
    deploymentTypes: initialDeploymentTypeToDeploymentTypes.backup,
  });

  const loads = useListLoadsQuery(
    {
      tenantName,
      createdBefore: null,
      createdSince,
    },
    { pollingInterval: refreshMS }
  );
  const cranes = useListCranesQuery({
    tenantName,
  });

  if (
    loads.data &&
    cranes.data &&
    activePlanMixes.data &&
    (nextActivePlanMixes.data !== undefined ||
      (nextActivePlanMixes.error &&
        "status" in nextActivePlanMixes.error &&
        nextActivePlanMixes.error.status === 404)) &&
    backupPlanMixes.data
  ) {
    return (
      <TabbedBody
        loads={loads.data}
        cranes={cranes.data.cranes}
        currentPlanId={activePlanMixes.data.plan_id}
        backupPlanId={backupPlanMixes.data.plan_id}
        nextPlanId={nextActivePlanMixes.data?.plan_id ?? null}
      />
    );
  }
  return null;
};

const TabbedBody = ({
  loads,
  cranes,
  currentPlanId,
  backupPlanId,
  nextPlanId,
}: TabbedBodyProps) => {
  const { t } = useTenantTranslation();
  const [tabIndex, setTabIndex] = React.useState(0);
  const [showLoadModalId, setShowLoadModalId] = React.useState<null | number>(
    null
  );
  const onLoadClick = (id: number) => {
    setShowLoadModalId(id);
  };

  const unassignedCranes = cranes.filter((crane) => {
    return loads
      .filter(isActive)
      .flatMap((load) => Object.values(load.baskets))
      .filter((basket) => basket.status !== "filled")
      .every((basket) => basket.assigned_to_uid !== crane.user_uid);
  });

  return (
    <>
      <Stack sx={{ height: "100%" }}>
        <TitledTabs
          sx={{ marginX: 2 }}
          tabIndex={tabIndex}
          onChange={setTabIndex}
          tabSpecs={[
            {
              title: t("chargeQueue"),
              key: "chargeQueue",
              content: (
                <Queue
                  onLoadClick={onLoadClick}
                  activeLoads={loads.filter((load) => isActive(load))}
                  pendingLoads={loads.filter((load) => isPending(load))}
                  unassignedCranes={unassignedCranes}
                  currentPlanId={currentPlanId}
                  backupPlanId={backupPlanId}
                  nextPlanId={nextPlanId}
                />
              ),
            },
            {
              title: t("history"),
              key: "history",
              content: (
                <Completed
                  onLoadClick={onLoadClick}
                  loads={loads.filter((load) => isComplete(load))}
                  currentPlanId={currentPlanId}
                  backupPlanId={backupPlanId}
                  nextPlanId={nextPlanId}
                />
              ),
            },
          ]}
        />
      </Stack>
      <LoadModal
        onClose={() => setShowLoadModalId(null)}
        loadId={showLoadModalId}
        cranes={cranes}
      />
    </>
  );
};

const Completed = ({
  loads,
  onLoadClick,
  currentPlanId,
  backupPlanId,
  nextPlanId,
}: CompletedProps) => {
  const { t } = useTenantTranslation();
  return (
    <Stack gap={1} paddingTop={1} paddingX={2}>
      {loads.length > 0 ? (
        loads.map((load) => {
          const {
            steel_grade_name,
            mix_id,
            copper_group_weight_percent_max,
            load_id,
            plan_id,
          } = load;
          return (
            <Load
              variant={getLoadVariant({
                planId: plan_id,
                currentPlanId,
                backupPlanId,
                nextPlanId,
              })}
              loadId={load_id}
              onClick={() => onLoadClick(load_id)}
              key={load_id}
              name={steel_grade_name}
              mixId={mix_id}
              copperGroupWeightPercentMax={copper_group_weight_percent_max}
            />
          );
        })
      ) : (
        <Typography variant="body1">{t("noCompletedCharges")}</Typography>
      )}
    </Stack>
  );
};

const LoadRow = (props: LoadRowProps) => {
  const { loadId } = props;
  const tenantName = useTenant();
  const [doDeleteLoadMutation] = useDeleteLoadMutation();
  return (
    <Stack
      sx={{
        alignItems: "center",
        flexDirection: "row",
        gap: 0,
        justifyContent: "space-between",
      }}
    >
      <Load {...props} />
      <IconButton
        onClick={() => doDeleteLoadMutation({ tenantName, loadId: loadId })}
        sx={{ paddingRight: 0 }}
      >
        <RemoveCircleOutlineOutlined />
      </IconButton>
    </Stack>
  );
};

const Queue = ({
  activeLoads,
  pendingLoads,
  onLoadClick,
  unassignedCranes,
  currentPlanId,
  backupPlanId,
  nextPlanId,
}: QueueProps) => {
  const { t } = useTenantTranslation();

  return (
    <Stack
      sx={{
        gap: 3,
        paddingY: 1,
        paddingX: 2,
        height: "100%",
        overflow: "scroll",
      }}
    >
      <Stack gap={1}>
        <Typography variant="h6">{t("active")}</Typography>
        {activeLoads.length > 0 ? (
          activeLoads.map((load) => {
            const {
              steel_grade_name,
              mix_id,
              copper_group_weight_percent_max,
              load_id,
              baskets,
              plan_id,
            } = load;

            const cranes = Object.values(baskets)
              .filter(
                (
                  basket: LoadBasketRead
                ): basket is LoadBasketRead & {
                  status: "new" | "partially_filled";
                  assigned_to_name: string;
                  assigned_to_uid: string;
                } =>
                  (basket.status === "new" ||
                    basket.status === "partially_filled") &&
                  basket.assigned_to_name !== null &&
                  basket.assigned_to_uid !== null
              )
              .map((basket) => ({
                name: basket.assigned_to_name,
                user_uid: basket.assigned_to_uid,
              }));

            return (
              <LoadRow
                variant={getLoadVariant({
                  planId: plan_id,
                  currentPlanId,
                  backupPlanId,
                  nextPlanId,
                })}
                key={load_id}
                loadId={load_id}
                onClick={() => onLoadClick(load_id)}
                name={steel_grade_name}
                mixId={mix_id}
                copperGroupWeightPercentMax={copper_group_weight_percent_max}
                cranes={cranes}
              />
            );
          })
        ) : (
          <Typography variant="body1">{t("noActiveCharges")}</Typography>
        )}
      </Stack>
      <Stack gap={1}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          <Typography variant="h6">{t("next")}</Typography>
          {unassignedCranes.map((crane) => (
            <CraneChip
              key={crane.user_uid}
              name={crane.name}
              variant="warning"
            />
          ))}
        </Box>
        {pendingLoads.length > 0 ? (
          pendingLoads.map((load) => {
            const {
              steel_grade_name,
              mix_id,
              copper_group_weight_percent_max,
              load_id,
              plan_id,
            } = load;
            return (
              <LoadRow
                variant={getLoadVariant({
                  currentPlanId,
                  backupPlanId,
                  planId: plan_id,
                  nextPlanId,
                })}
                key={load_id}
                loadId={load_id}
                onClick={() => onLoadClick(load_id)}
                name={steel_grade_name}
                mixId={mix_id}
                copperGroupWeightPercentMax={copper_group_weight_percent_max}
              />
            );
          })
        ) : (
          <Typography variant="body1">{t("noQueuedCharges")}</Typography>
        )}
      </Stack>
    </Stack>
  );
};
