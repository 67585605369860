import React, { type JSX } from "react";
import { useSearchParams } from "react-router";

import { createContext, createUseContext } from "./context";
import { useSearch } from "contexts/search/provider";
import { mapLoaded } from "models/loaded";

export type PlanId = number & { __planId__: true };

type Props = {
  fallback: JSX.Element;
};

const Context = createContext<PlanId>();

export const usePlanId = createUseContext(Context, "planId");

export const useSetPlanId = () => {
  const [, setSearchParams] = useSearchParams();
  const setPlanId = React.useCallback(
    (planId: PlanId, replace = false) => {
      setSearchParams(
        (searchParams) => {
          searchParams.set("plan", planId.toString());
          return searchParams;
        },
        { replace }
      );
    },
    [setSearchParams]
  );

  return setPlanId;
};

export const PlanIdBoundary = ({
  fallback,
  children,
}: React.PropsWithChildren<Props>) => {
  const [searchParams] = useSearchParams();
  const {
    results: { results },
  } = useSearch();
  const setPlanId = useSetPlanId();

  const loadedPlanIds = mapLoaded(
    results,
    (result) =>
      (result?.plans
        .slice(0)
        .sort((planA, planB) => planB.id - planA.id)
        .map(({ id }) => id) ?? []) as PlanId[]
  );

  const planId = searchParams.get("plan");

  const isNull = planId === null;
  const isBelowMinimum = !isNull && Number(planId) < 0;
  const isInteger = !isNull && Number.isInteger(Number(planId));
  const isValid = !isBelowMinimum && isInteger;

  const hasLoadedPlans = loadedPlanIds.status === "success";
  const searchHasPlans = hasLoadedPlans && loadedPlanIds.data.length > 0;
  const isPlanInSearch =
    isValid &&
    hasLoadedPlans &&
    loadedPlanIds.data.includes(Number(planId) as PlanId);

  React.useEffect(() => {
    if (!isValid && hasLoadedPlans && searchHasPlans) {
      setPlanId(loadedPlanIds.data[0]!, true);
    }
    if (!isPlanInSearch && searchHasPlans) {
      setPlanId(loadedPlanIds.data[0]!, true);
    }
  }, [
    isValid,
    hasLoadedPlans,
    searchHasPlans,
    setPlanId,
    loadedPlanIds,
    isPlanInSearch,
  ]);

  if (isPlanInSearch) {
    const numberPlanId = Number(planId) as PlanId;
    return <Context.Provider value={numberPlanId}>{children}</Context.Provider>;
  } else if (hasLoadedPlans && !searchHasPlans) {
    return fallback;
  } else {
    return null;
  }
};
