import React from "react";
import { CheckCircle } from "@mui/icons-material";
import { Box, SxProps, Typography, useTheme } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { LoadBasketLayerRead } from "src/store/api/generatedApi";

type Props = {
  layers: LoadBasketLayerRead[];
  completed: boolean;
  size: "big" | "medium";
  sx?: SxProps;
};

const defaultSx: SxProps = {};

const getOpacity = (faded: boolean) => (faded ? 0.5 : 1);

type BasketLabelProps = {
  children: string;
  faded: boolean;
  size: "big" | "medium";
};

const getTypographyVariant = (size: "big" | "medium") => {
  switch (size) {
    case "big":
      return "h1";
    case "medium":
      return "h5";
  }
};

const getPadding = (size: "big" | "medium") => {
  switch (size) {
    case "big":
      return 2;
    case "medium":
      return 0.5;
  }
};

const BasketLabel = ({ children, faded, size }: BasketLabelProps) => {
  const theme = useTheme();
  return (
    <Typography
      variant={getTypographyVariant(size)}
      sx={{
        backgroundColor: theme.palette.grey[50],
        borderColor: theme.palette.grey[200],
        borderStyle: "solid",
        borderWidth: 1,
        padding: getPadding(size),
        opacity: getOpacity(faded),
        fontSize: size === "big" ? 48 : undefined,
      }}
    >
      {children}
    </Typography>
  );
};

const getColumnWidth = (size: "big" | "medium"): 200 | 100 => {
  switch (size) {
    case "big":
      return 200;
    case "medium":
      return 100;
  }
};

export const Basket = ({ layers, completed, size, sx = defaultSx }: Props) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        ...sx,
        display: "grid",
        position: "relative",
        gridTemplateColumns: `repeat(2, ${getColumnWidth(size)}px)`,
        gridAutoRows: `max-content`,
        gap: 1,
      }}
    >
      {completed ? (
        <CheckCircle
          sx={{
            position: "absolute",
            top: "40%",
            left: "50%",
            zIndex: 2,
            transform: "translate(-50%, -50%)",
            fontSize: 84,
          }}
          color="success"
        />
      ) : null}
      {layers.map((layer) => (
        <React.Fragment key={layer.layer_number}>
          <BasketLabel faded={completed} size={size}>
            {layer.material_name}
          </BasketLabel>
          <BasketLabel faded={completed} size={size}>
            {layer.mass.toFixed(0)}
          </BasketLabel>
        </React.Fragment>
      ))}
      <Box
        sx={{
          gridColumn: "1/-1",
          height: 50,
          display: "grid",
          placeItems: "center",
          opacity: getOpacity(completed),
        }}
      >
        <Typography
          variant={getTypographyVariant(size)}
          sx={{ color: theme.palette.text.secondary }}
        >
          {t("totalMass")}
          {": "}
          {layers
            .reduce((acc, layer) => acc + Math.round(layer.mass), 0)
            .toFixed()}
        </Typography>
      </Box>
    </Box>
  );
};
