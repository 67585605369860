import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VERSION } from "src/constants";
import { RootState } from "store/store.tsx";

export interface VersionState {
  browserVersion: string;
  serverVersion: string;
}

const initialState: VersionState = {
  browserVersion: VERSION,
  serverVersion: VERSION, // Start off matching the browserVersion
};

const versionSlice = createSlice({
  name: "version",
  initialState,
  reducers: {
    setServerVersion(state, action: PayloadAction<string>) {
      state.serverVersion = action.payload;
    },
  },
});

export const { setServerVersion } = versionSlice.actions;

const createAppSelector = createSelector.withTypes<RootState>();

// Selector that returns true if the browser and server versions differ.
export const selectIsVersionOutOfDate = createAppSelector(
  (state) => state.version.browserVersion,
  (state) => state.version.serverVersion,
  (browserVersion, serverVersion) => browserVersion !== serverVersion
);

export default versionSlice.reducer;
