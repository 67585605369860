import React from "react";
import { useAppSelector } from "store/store.tsx";
import { selectIsVersionOutOfDate } from "store/slices/versionSlice.ts";

export const RequireVersionRefresh = ({
  children,
}: React.PropsWithChildren) => {
  const isOutOfDate = useAppSelector(selectIsVersionOutOfDate);
  if (isOutOfDate) {
    window.location.reload();
  }
  return children;
};
