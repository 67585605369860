import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { RootState } from "store/store";
import { PlanMixBasket } from "../api/generatedApi";

type PlanMixBasketMaterialEdit = {
  planId: number;
  mixes: Record<number, Record<string, PlanMixBasket>>;
};

const planMixBasketMaterialEditsAdapter = createEntityAdapter({
  selectId: (editBasketMaterials: PlanMixBasketMaterialEdit) =>
    editBasketMaterials.planId,
});

export const planMixBasketMaterialEditsSlice = createSlice({
  name: "planMixBasketMaterialEdits",
  initialState: planMixBasketMaterialEditsAdapter.getInitialState(
    undefined,
    []
  ),
  reducers: {
    set(state, { payload }: PayloadAction<PlanMixBasketMaterialEdit>) {
      planMixBasketMaterialEditsAdapter.setOne(state, payload);
    },
    clear(state, { payload }: PayloadAction<number>) {
      planMixBasketMaterialEditsAdapter.removeOne(state, payload);
    },
  },
});

const createAppSelector = createSelector.withTypes<RootState>();

const adapterSelectors = planMixBasketMaterialEditsAdapter.getSelectors();

export const selectPlanMixBasketMaterialEdits = createAppSelector(
  [(state) => state.planMixBasketMaterialEdits, (_, planId: number) => planId],
  (state, planId) => {
    return adapterSelectors.selectById(state, planId);
  }
);
