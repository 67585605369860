import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, useStore } from "react-redux";
import { createReduxEnhancer } from "@sentry/react";

import { backendApi } from "./api/enhancedApi";

import { settingsSlice } from "./slices/settings";
import { mixMaterialLimitsSlice } from "./slices/mixMaterialLimits";
import { chefGroupBasketMaterialLimitSetSlice } from "./slices/chefGroupBasketMaterialLimits";
import { setupListeners } from "@reduxjs/toolkit/query";
import versionSlice from "store/slices/versionSlice.ts";
import { steelGradeSearchSlice } from "./slices/steelGradeSearchSlice";
import { planMixBasketMaterialEditsSlice } from "./slices/planMixBasketMaterialEdits";

export const store = configureStore({
  reducer: {
    settings: settingsSlice.reducer,
    version: versionSlice,
    mixMaterialLimits: mixMaterialLimitsSlice.reducer,
    chefGroupBasketMaterialLimits: chefGroupBasketMaterialLimitSetSlice.reducer,
    [steelGradeSearchSlice.reducerPath]: steelGradeSearchSlice.reducer,
    [planMixBasketMaterialEditsSlice.reducerPath]:
      planMixBasketMaterialEditsSlice.reducer,
    [backendApi.reducerPath]: backendApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // When queries return files, we need to preprocess them into blobs
        // before they get stored.  Since blobs are not serialisable by default,
        // this means the store - and some actions - may contain non-serialisable
        // state.  If this becomes an issue, it's probably possible to convert
        // the content of the blob into a bytes object, but for now it's fine to
        // leave as-is.
        ignoredActions: ["api/executeQuery/fulfilled"],
        ignoredPaths: ["api.queries"],
      },
    }).concat(backendApi.middleware),
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(createReduxEnhancer()),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppStore = useStore.withTypes<AppStore>();
