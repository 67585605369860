import { Box, Button, Stack, Typography } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { PlanBackupSummary } from "store/api/generatedApi";
import { OpenInNew } from "@mui/icons-material";
import { SummaryStat } from "components/common/summaryStat";
import theme from "src/theme";

type BackupCardProps = {
  backup: PlanBackupSummary;
};

export const BackupCard = ({ backup }: BackupCardProps) => {
  const { t } = useTenantTranslation();

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: theme.palette.background.default,
        p: 2,
        border: 1,
        borderRadius: 1,
        "&:hover": {
          boxShadow: (theme) =>
            `2px 3px 0px 0px #000,inset 5px 0px 0px 0px ${theme.palette.primary.main};`,
          cursor: "pointer",
        },
        borderColor: theme.palette.common.black,
        transition: "box-shadow 0.2s ease-in-out",
      }}
    >
      <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
        <Typography variant="h6" gutterBottom>
          {backup.display_name}
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <SummaryStat
            label={t("tappedPrice")}
            value={backup.display_cost_per_tonne}
            decimalPlaces={2}
            units="specific_cost"
            hideDivider
          />
          <Button
            variant="outlined"
            endIcon={<OpenInNew />}
            color="primary"
            sx={{ textDecoration: "none" }}
            onClick={() => {
              const newParams = new URLSearchParams();
              newParams.set("search", backup.search_id.toString());
              newParams.set("plan", backup.plan_id.toString());
              window.open(
                `${window.location.pathname}?${newParams.toString()}`,
                "_blank"
              );
            }}
          >
            {t("viewPlan")}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
