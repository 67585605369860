import { Box, useTheme } from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { TargetInventoryDependencies } from "./dependencies";
import { TargetInventoryItem } from "store/api/generatedApi";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";

import { useHandleTableMutations } from "./useHandleTableMutations";
import { useSortDataGridByMaterial } from "src/components/common/dataGrid/utilities";
import { NumberCell } from "src/components/common/dataGrid/NumberCell";
import { MaterialPropertyViewCell } from "../common/MaterialPropertyViewCell";

export type TargetInventoryRow = TargetInventoryItem & {
  material_name: string;
};

export const TargetInventoryTable = ({
  materials,
  targetInventory,
}: TargetInventoryDependencies) => {
  const theme = useTheme();
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(true);

  const { materialNameColumnSortComparator, materialNameSortModel } =
    useSortDataGridByMaterial(materials);

  const { handleUpdateItem } = useHandleTableMutations();

  const rows = materials.byIndex.map((material) => {
    const item: TargetInventoryItem = targetInventory.find(
      (item) => item.material_id === material.id
    ) ?? { material_id: material.id, min_quantity: null, max_quantity: null };
    return { ...item, material_name: material.name };
  });

  const columns: GridColDef<TargetInventoryRow>[] = [
    {
      field: "material_name",
      headerName: t("material"),
      sortable: true,
      sortComparator: materialNameColumnSortComparator,
      flex: 1,
      renderCell: (params) => (
        <MaterialPropertyViewCell
          name={params.row.material_name}
          id={params.row.material_id}
        />
      ),
      display: "flex",
    },
    {
      field: "min_quantity",
      headerName: `${t("targetMin")}${units("mass")}`,
      sortable: false,
      editable: true,
      flex: 1,
      renderCell: (params) => <NumberCell value={params.row.min_quantity} />,
      display: "flex",
    },
    {
      field: "max_quantity",
      headerName: `${t("targetMax")}${units("mass")}`,
      sortable: false,
      editable: true,
      flex: 1,
      renderCell: (params) => <NumberCell value={params.row.max_quantity} />,
      display: "flex",
    },
  ];

  return (
    <Box
      // Prevent the modal from closing when pressing Escape key while in edit mode in the data grid
      onKeyDown={(e) => e.key === "Escape" && e.stopPropagation()}
    >
      <DataGridPremium
        rows={rows}
        columns={columns}
        getRowId={(row: TargetInventoryRow) => row.material_id}
        processRowUpdate={handleUpdateItem}
        sx={{
          height: 500,
          "& .MuiDataGrid-cell--editing input": {
            textAlign: "right",
            paddingX: 1,
            ...theme.typography.body1Mono,
          },
        }}
        initialState={{ sorting: materialNameSortModel }}
        cellSelection
        ignoreValueFormatterDuringExport
      />
    </Box>
  );
};
