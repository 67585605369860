import { WarningAmber } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Typography } from "@mui/material";

import { useTenantTranslation } from "hooks/formatters";
import { useCreateSearchFromContextMutation } from "src/store/api/generatedApi";

type Props = {
  searchId: number;
  tenantName: string;
  onRefresh: (_: number) => void;
};

export const ConstraintsAlert = ({
  searchId,
  tenantName,
  onRefresh,
}: Props) => {
  const { t } = useTenantTranslation();
  const [doCreateSearch, createSearchMutation] =
    useCreateSearchFromContextMutation();

  const handleOnClick = async () => {
    const { id } = await doCreateSearch({
      tenantName,
      searchId,
    }).unwrap();
    onRefresh(id!);
  };

  return (
    <Alert
      sx={{
        alignItems: "center",
        ".MuiAlert-action": {
          paddingTop: "0px !important",
        },
      }}
      severity="error"
      icon={<WarningAmber />}
      action={
        <LoadingButton
          loading={!createSearchMutation.isUninitialized}
          onClick={handleOnClick}
          color="secondary"
        >
          {t("refreshConstraints")}
        </LoadingButton>
      }
    >
      <Typography variant="body1">{t("constraintsHaveChanged")}</Typography>
    </Alert>
  );
};
