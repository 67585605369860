import { Replay } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";

type StaticProps = {
  mixNumber: number;
};

type RevertProps = {
  mixNumber: number;
  onClick: () => void;
  isLoading: boolean;
};

type Props = StaticProps | RevertProps;

export const MixNumberCell = ({ mixNumber, ...props }: Props) => {
  const theme = useTheme();
  const isInteractive = "onClick" in props && "isLoading" in props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="body1Mono">{mixNumber}</Typography>
      {isInteractive ? (
        <IconButton onClick={props.onClick}>
          <Replay
            sx={{
              ...(props.isLoading
                ? {
                    animation: "spin 2000ms infinite linear",
                    fill: theme.palette.primary.main,
                  }
                : {}),
              "@keyframes spin": {
                from: {
                  transform: "rotate(360deg)",
                },
                to: {
                  transform: "rotate(0deg)",
                },
              },
            }}
          />
        </IconButton>
      ) : null}
    </Box>
  );
};
