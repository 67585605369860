import { useTenant } from "hooks/settings";
import { Loaded, loadedEndpoint } from "models/loaded";
import {
  PlanBackupSummaries,
  useGetBackupsQuery,
} from "src/store/api/generatedApi";

export const useBackups = (
  planId: number | null
): Loaded<PlanBackupSummaries> => {
  if (planId == null) {
    return { status: "waiting" };
  } else {
    return loadedEndpoint(
      useGetBackupsQuery({
        tenantName: useTenant(),
        planId: planId,
      })
    );
  }
};
