import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";

export type Mode = "productGroup" | "steelGrade";

export const InputModeSelector = ({
  mode,
  setMode,
  convert,
}: {
  mode: Mode;
  setMode: (mode: Mode) => void;
  convert: { message: string; doConversion: () => void } | null;
}) => {
  const { t } = useTenantTranslation();

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ mr: 1 }}>{t("addAs")}:</Typography>
      <RadioGroup
        row
        aria-labelledby="production-field-type-label"
        name="production-field-type"
      >
        <FormControlLabel
          value="productGroup"
          control={<Radio color="secondary" />}
          label={t("addAsProductGroups")}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setMode("productGroup");
          }}
          checked={mode === "productGroup"}
        />
        <FormControlLabel
          value="steelGrade"
          control={<Radio color="secondary" />}
          label={t("addAsProducts")}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setMode("steelGrade");
          }}
          checked={mode === "steelGrade"}
        />
      </RadioGroup>
      {convert ? (
        <Alert severity="info">
          {convert.message}
          <Button onClick={convert.doConversion} sx={{ ml: 1, padding: 0 }}>
            {t("convert")}
          </Button>
        </Alert>
      ) : null}
    </Box>
  );
};
