import React from "react";
import dayjs from "dayjs";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { Box, Button, Skeleton, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  MaterialRead,
  NamedPlanMix,
  PlanMixBasket,
  SteelGrade,
} from "src/store/api/generatedApi";

import { useTenantTranslation } from "hooks/formatters";

import { Table } from "./Table";
import { areMixesEqual } from "./utils";
import { MultiSelect } from "../inputs/multiSelect";
import { useAppDispatch, useAppSelector, useAppStore } from "src/store/store";
import {
  selectSearchedSteelGradesIds,
  steelGradeSearchSlice,
} from "src/store/slices/steelGradeSearchSlice";
import {
  planMixBasketMaterialEditsSlice,
  selectPlanMixBasketMaterialEdits,
} from "src/store/slices/planMixBasketMaterialEdits";

export type BaseEditableTableProps = {
  planId: number;
  materials: MaterialRead[];
  mixes: NamedPlanMix[];
  targetTappedMass: number;
  materialDecimalYields: Record<number, number>;
  steelGradeColumnWidth: number;
  steelGrades: Record<number, SteelGrade>;
  disableEdit: boolean;
  defaultDRIMaterialId: number;
  onSteelGradeClick?: ({
    mixId,
    steelGradeId,
  }: {
    mixId: number;
    steelGradeId: number;
  }) => void;
  onSubmit: (editedMixes: NamedPlanMix[]) => Promise<void>;
  materialPrices: Record<number, number | null> | null;
};

type DatedEditableTableProps = BaseEditableTableProps & {
  startAt: Date;
  endAt: Date;
};

type EditableTableProps = BaseEditableTableProps | DatedEditableTableProps;

export const DataGridSkeleton = () => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="200px repeat(25,1fr)"
      gridAutoRows="min-content"
      sx={{
        columnGap: 0.5,
        rowGap: 0.5,
        overflow: "hidden",
        height: "calc(100% - 24px)",
        margin: 1,
      }}
    >
      <Box sx={{ display: "flex", gridColumn: "1/-1", gap: 1 }}>
        <Skeleton variant="text">
          <Typography variant="h6">The first date in here</Typography>
        </Skeleton>
        <Skeleton variant="text">
          <Typography variant="h6">The last date in here</Typography>
        </Skeleton>
        <Skeleton sx={{ ml: "auto" }}>
          <Button>Discard changes</Button>
        </Skeleton>
        <Skeleton>
          <Button>Save changes</Button>
        </Skeleton>
      </Box>
      {new Array(26).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton height={60} key={`${index}.column`} variant="rectangular" />
      ))}
      {new Array(26 * 20).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton height={40} key={`${index}.cell`} variant="rectangular" />
      ))}
    </Box>
  );
};

export const EditableTable = ({
  planId,
  mixes,
  materials,
  materialDecimalYields,
  materialPrices,
  steelGradeColumnWidth,
  targetTappedMass,
  steelGrades,
  disableEdit,
  defaultDRIMaterialId,
  onSteelGradeClick,
  onSubmit,
  ...props
}: EditableTableProps) => {
  const apiRef = useGridApiRef();

  const { t } = useTenantTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const store = useAppStore();

  const selectedSteelGradeIds = useAppSelector((state) =>
    selectSearchedSteelGradesIds(state, planId)
  );

  const handleSetSelectedSearchSteelGradeIds = (steelGrades: SteelGrade[]) => {
    dispatch(
      steelGradeSearchSlice.actions.setSteelGrades([
        planId,
        steelGrades.map((steelGrade) => steelGrade.id),
      ])
    );
  };

  const editedMixBaskets = useAppSelector((state) =>
    selectPlanMixBasketMaterialEdits(state, planId)
  );

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [revision, setRevision] = React.useState(0);

  const handleOnEdit = React.useCallback(
    (mixId: number, baskets: Record<string, PlanMixBasket>) => {
      const editedMixBaskets = selectPlanMixBasketMaterialEdits(
        store.getState(),
        planId
      );
      const newMixes = editedMixBaskets
        ? { ...editedMixBaskets.mixes, [mixId]: baskets }
        : { [mixId]: baskets };

      dispatch(
        planMixBasketMaterialEditsSlice.actions.set({
          planId,
          mixes: newMixes,
        })
      );
    },
    [dispatch, planId, store, selectPlanMixBasketMaterialEdits]
  );

  const tableMixes = React.useMemo(() => {
    return mixes.map((mix) => {
      const editedBaskets = editedMixBaskets
        ? editedMixBaskets.mixes[mix.mix_id]
        : undefined;
      if (editedBaskets !== undefined) {
        return { ...mix, baskets: { ...editedBaskets } };
      } else {
        return mix;
      }
    });
  }, [editedMixBaskets, mixes]);

  const handleOnCancel = React.useCallback(() => {
    setRevision((revision) => revision + 1);
    dispatch(planMixBasketMaterialEditsSlice.actions.clear(planId));
  }, [setRevision, dispatch, planId]);

  const hasEdited =
    !disableEdit &&
    editedMixBaskets !== undefined &&
    !areMixesEqual(
      mixes.filter((mix) => mix.mix_id in editedMixBaskets.mixes),
      tableMixes.filter((mix) => mix.mix_id in editedMixBaskets.mixes)
    );

  const handleOnSubmit = async () => {
    setIsSubmitting(true);
    const submitMixes = mixes.map((mix) => {
      const editedBaskets = editedMixBaskets
        ? editedMixBaskets.mixes[mix.mix_id]
        : undefined;
      if (editedBaskets !== undefined) {
        return {
          ...mix,
          baskets: editedBaskets,
        };
      } else {
        return mix;
      }
    });
    await onSubmit(submitMixes);
    setIsSubmitting(false);
  };

  const handleOnSteelGradeClick = React.useMemo(() => {
    if (onSteelGradeClick) {
      return (mixId: number) => (steelGradeId: number) =>
        onSteelGradeClick({ mixId, steelGradeId });
    }
  }, [onSteelGradeClick]);

  const steelGradeOptions = [
    ...new Set(mixes.flatMap((mix) => mix.steel_grade_ids)),
  ]
    .map((steelGradeId) => steelGrades[steelGradeId])
    .filter(
      (steelGrade: SteelGrade | undefined): steelGrade is SteelGrade =>
        steelGrade !== undefined
    )
    .sort((steelGradeA, steelGradeB) =>
      steelGradeA.name < steelGradeB.name
        ? 1
        : steelGradeA.name > steelGradeB.name
        ? -1
        : 0
    );

  const viewableMixes = React.useMemo(() => {
    if (selectedSteelGradeIds.length === 0) {
      return tableMixes;
    }
    return tableMixes.filter((mix) =>
      mix.steel_grade_ids.some((steelGrade) =>
        selectedSteelGradeIds.includes(steelGrade)
      )
    );
  }, [selectedSteelGradeIds, mixes, apiRef]);

  const isDated = "startAt" in props && "endAt" in props;

  return (
    <>
      <Box
        sx={{
          width: "100%",
          my: 1,
          alignItems: "center",
          gap: 1,
          display: "grid",
          gridTemplateColumns: `${
            isDated ? "min-content" : ""
          } minmax(min-content, 1fr) repeat(2, min-content)`,
        }}
      >
        {isDated ? (
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.text.secondary,
              fontWeight: "bold",
              whiteSpace: "nowrap",
            }}
          >
            {dayjs(props.startAt).format("MMM, D YYYY HH:mm")} -&nbsp;
            {dayjs(props.endAt).format("MMM, D YYYY HH:mm")}
          </Typography>
        ) : null}
        <MultiSelect<SteelGrade, number>
          sx={{
            minWidth: 250,
            minHeight: 40,
            marginRight: "auto",

            ".MuiAutocomplete-tag": {
              backgroundColor: theme.palette.grey[600],
              color: theme.palette.common.white,
              svg: {
                "&:hover": {
                  fill: theme.palette.grey[600],
                  path: {
                    fill: theme.palette.common.white,
                  },
                },
              },
            },
          }}
          options={steelGradeOptions}
          getOptionId={(steelGrade) => steelGrade.id}
          getOptionLabel={(steelGrade) => steelGrade.name}
          values={selectedSteelGradeIds.map((id) => steelGrades[id]!)}
          setValues={handleSetSelectedSearchSteelGradeIds}
          label={t("searchForSteelGrade")}
          small
        />
        <Button
          disabled={!hasEdited || isSubmitting}
          onClick={handleOnCancel}
          color="primary"
          variant="text"
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {t("discardChanges")}
        </Button>
        <LoadingButton
          loading={isSubmitting}
          disabled={!hasEdited}
          onClick={handleOnSubmit}
          color="primary"
          variant="contained"
          sx={{ whiteSpace: "nowrap" }}
        >
          {t("saveChanges")}
        </LoadingButton>
      </Box>
      <Table
        onSteelGradeClick={handleOnSteelGradeClick}
        steelGradeColumnWidth={steelGradeColumnWidth}
        disableEdit={disableEdit}
        key={revision}
        mixes={viewableMixes}
        defaultDRIMaterialId={defaultDRIMaterialId}
        materials={materials}
        materialDecimalYields={materialDecimalYields}
        targetTappedMass={targetTappedMass}
        selectedSteelGrades={selectedSteelGradeIds}
        steelGrades={steelGrades}
        apiRef={apiRef}
        onEdit={handleOnEdit}
        materialPrices={materialPrices}
      />
    </>
  );
};
