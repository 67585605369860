import React from "react";
import { useNavigate } from "react-router";
import {
  Alert,
  Box,
  Button,
  Skeleton,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";

import {
  DeploymentType,
  NamedPlanMix,
  useGetContextForPlanQuery,
  useGetMaterialMetadataSetQuery,
  useGetParametersForPlanQuery,
  useGetDeployedPlanMixesQuery,
  useUpdateAndDeployPlanMixesMutation,
  PlanMixUpdate,
  useCreateLoadMutation,
  generatedApi,
  Latest,
  Next,
} from "src/store/api/generatedApi";

import { useTenant } from "hooks/settings";
import { useTenantTranslation } from "hooks/formatters";

import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";
import { sumRecords } from "src/utils/sumRecords";
import { selectIsVersionOutOfDate } from "src/store/slices/versionSlice";
import { AppDispatch, useAppDispatch, useAppSelector } from "src/store/store";

import { TitledTabs } from "components/common/titledTabs";
import { EditableTable } from "components/common/plan/EditableTable";

import { getDRIMass } from "./utils";
import { LoadQueue } from "./LoadQueue";
import { cleanBaskets, isMixEqual } from "components/common/plan/utils";
import { steelGradeSearchSlice } from "src/store/slices/steelGradeSearchSlice";

type PlanTimeframeToggleProps = {
  value: Latest | Next;
  onChange: (value: Latest | Next) => void;
};

type InitialDeploymentType = "standard" | "backup";

export const initialDeploymentTypeToDeploymentTypes: Record<
  InitialDeploymentType,
  DeploymentType[]
> = {
  standard: ["standard", "edited_standard"],
  backup: ["backup", "edited_backup"],
};

type Props = {
  initialDeploymentType: InitialDeploymentType;
};

type PlanWrapperProps = {
  initialDeploymentType: InitialDeploymentType;
  planTimeframe: Latest | Next;
};

type PlanProps = {
  deploymentType: DeploymentType;
  id: number;
  mixes: NamedPlanMix[];
  startAt: Date;
  endAt: Date;
  defaultDRIMaterialId: number;
};

export const DataGridSkeleton = () => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="200px repeat(25,1fr)"
      gridAutoRows="min-content"
      sx={{
        columnGap: 0.5,
        rowGap: 0.5,
        overflow: "hidden",
        height: "calc(100% - 24px)",
        margin: 1,
      }}
    >
      <Box sx={{ display: "flex", gridColumn: "1/-1", gap: 1 }}>
        <Skeleton variant="text">
          <Typography variant="h6">The first date in here</Typography>
        </Skeleton>
        <Skeleton variant="text">
          <Typography variant="h6">The last date in here</Typography>
        </Skeleton>
        <Skeleton sx={{ ml: "auto" }}>
          <Button>Discard changes</Button>
        </Skeleton>
        <Skeleton>
          <Button>Save changes</Button>
        </Skeleton>
      </Box>
      {new Array(26).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton height={60} key={`${index}.column`} variant="rectangular" />
      ))}
      {new Array(26 * 20).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton height={40} key={`${index}.cell`} variant="rectangular" />
      ))}
    </Box>
  );
};

const PlanTimeframeToggle = ({ value, onChange }: PlanTimeframeToggleProps) => {
  const theme = useTheme();
  const { t } = useTenantTranslation();

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {value === "next" ? (
        <Alert variant="outlined" severity="warning" sx={{ paddingY: 0 }}>
          {t("youAreCurrentlyViewingTheNextPlan")}
        </Alert>
      ) : null}
      <ToggleButtonGroup
        sx={{ borderColor: theme.palette.grey[500] }}
        color="primary"
        size="small"
        value={value}
        exclusive
        onChange={(_, planTimeframe) =>
          onChange(planTimeframe as Latest | Next)
        }
      >
        <ToggleButton
          sx={{
            borderColor:
              value === "latest"
                ? theme.palette.primary.main
                : theme.palette.grey[500],
          }}
          value="latest"
        >
          {t("latest")}
        </ToggleButton>
        <ToggleButton
          sx={{
            borderColor:
              value === "next"
                ? theme.palette.primary.main
                : theme.palette.grey[500],
          }}
          value="next"
        >
          {t("next")}
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export const SupervisorView = ({ initialDeploymentType }: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const isOutOfDate = useAppSelector(selectIsVersionOutOfDate);

  const { t } = useTenantTranslation();

  const [planTimeframe, setPlanTimeframe] = React.useState<Latest | Next>(
    "latest"
  );

  const tabIndex = React.useMemo(() => {
    switch (initialDeploymentType) {
      case "standard":
        return 0;
      case "backup":
        return 1;
    }
  }, [initialDeploymentType]);

  const handleOnChange = React.useCallback(
    (index: number) => {
      switch (index) {
        case 0: {
          void navigate("../active");
          break;
        }
        case 1: {
          void navigate("../backup");
          break;
        }
      }
    },
    [navigate]
  );

  return (
    <>
      <Box
        sx={{
          display: "grid",
          width: "100vw",
          gridTemplateColumns: "1fr 300px",
          gridTemplateRows: "min-content 1fr",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 32px)",
            overflow: "hidden",
            gridRow: "1 / span 2",
            borderRight: "1px solid black",
            display: "flex",
            flexDirection: "column",
            paddingX: 2,
            marginBottom: 1,
          }}
        >
          <TitledTabs
            sx={{ alignItems: "center" }}
            tabIndex={tabIndex}
            onChange={handleOnChange}
            rightComponent={
              initialDeploymentType === "standard" ? (
                <PlanTimeframeToggle
                  value={planTimeframe}
                  onChange={setPlanTimeframe}
                />
              ) : undefined
            }
            tabSpecs={[
              {
                title: t("active"),
                key: "active",
                content: (
                  <PlanWrapper
                    initialDeploymentType="standard"
                    planTimeframe={planTimeframe}
                  />
                ),
              },
              {
                title: t("backup"),
                key: "backup",
                content: (
                  <PlanWrapper
                    initialDeploymentType="backup"
                    planTimeframe="latest"
                  />
                ),
              },
            ]}
          />
        </Box>
        <Box
          sx={{
            gridRow: "1 / span 2",
            minHeight: 1,
            height: "100%",
            overflow: "hidden",
            backgroundColor: theme.palette.grey[50],
            borderLeftWidth: 1,
            borderLeftColor: theme.palette.divider,
            borderLeftStyle: "solid",
          }}
        >
          <LoadQueue />
        </Box>
      </Box>
      <Snackbar
        open={isOutOfDate}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="warning"
          action={
            <Button
              color="primary"
              size="medium"
              onClick={() => window.location.reload()}
            >
              {t("refresh")}
            </Button>
          }
        >
          {t("newVersionNotification")}
        </Alert>
      </Snackbar>
    </>
  );
};

const PlanWrapper = ({
  initialDeploymentType,
  planTimeframe,
}: PlanWrapperProps) => {
  const tenantName = useTenant();
  const { t } = useTenantTranslation();
  const deploymentTypes =
    initialDeploymentTypeToDeploymentTypes[initialDeploymentType];

  const { data: planMixes, error } = useGetDeployedPlanMixesQuery({
    tenantName,
    planId: planTimeframe,
    period: 1,
    deploymentTypes,
  });

  if (error && "status" in error && error.status === 404) {
    return (
      <Box sx={{ display: "grid", placeItems: "center", flexGrow: 1 }}>
        <Typography variant="subtitle1">{t("planNotFound")}</Typography>
      </Box>
    );
  }

  if (planMixes) {
    const {
      plan_id,
      mixes,
      start_at,
      end_at,
      deployment_type,
      default_dri_id,
    } = planMixes;
    return (
      <Plan
        deploymentType={deployment_type}
        key={deployment_type}
        id={plan_id}
        mixes={mixes}
        startAt={new Date(start_at)}
        endAt={new Date(end_at)}
        defaultDRIMaterialId={default_dri_id}
      />
    );
  }
  return <DataGridSkeleton />;
};

const handleOnSubmit =
  (
    dispatch: AppDispatch,
    tenantName: string,
    startAt: Date,
    endAt: Date,
    planId: number,
    originalMixes: NamedPlanMix[],
    mutation: ReturnType<typeof useUpdateAndDeployPlanMixesMutation>[0]
  ) =>
  (materialDecimalYields: Record<number, number>, targetTappedMass: number) =>
  async (editedMixes: NamedPlanMix[]): Promise<void> => {
    void (await mutation({
      tenantName,
      planId,
      deployedPlanMixUpdates: {
        period: 1,
        mixes: createPlanMixUpdates(
          originalMixes,
          editedMixes,
          materialDecimalYields,
          targetTappedMass
        ),
        replacements: {},
        start_at: startAt.toISOString(),
        end_at: endAt.toISOString(),
        deployment_type: "edited_standard",
      },
    }));

    const planMixes = dispatch(
      generatedApi.endpoints.getDeployedPlanMixes.initiate(
        {
          tenantName,
          planId: "latest",
          deploymentTypes: initialDeploymentTypeToDeploymentTypes.standard,
          period: 1,
        },
        { forceRefetch: true }
      )
    );
    const { data } = await planMixes;

    if (data) {
      const planContext = dispatch(
        generatedApi.endpoints.getContextForPlan.initiate({
          tenantName,
          planId: data.plan_id,
        })
      );
      const planParameters = dispatch(
        generatedApi.endpoints.getParametersForPlan.initiate({
          tenantName,
          planId: data.plan_id,
        })
      );
      dispatch(steelGradeSearchSlice.actions.copy([planId, data.plan_id]));
      await Promise.all([planContext, planParameters]);
    }
  };

const useHandleOnSubmit = (
  startAt: Date,
  endAt: Date,
  planId: number,
  originalMixes: NamedPlanMix[]
) => {
  const dispatch = useAppDispatch();
  const tenantName = useTenant();
  const [mutation] = useUpdateAndDeployPlanMixesMutation();
  const callback = React.useMemo(() => {
    return handleOnSubmit(
      dispatch,
      tenantName,
      startAt,
      endAt,
      planId,
      originalMixes,
      mutation
    );
  }, [dispatch, tenantName, startAt, endAt, planId, mutation]);

  return callback;
};

export const Plan = ({
  id,
  mixes,
  startAt,
  endAt,
  deploymentType,
  defaultDRIMaterialId,
}: PlanProps) => {
  const tenantName = useTenant();
  const { data: planContext } = useGetContextForPlanQuery({
    tenantName,
    planId: id,
  });
  const { data: planParameters } = useGetParametersForPlanQuery({
    tenantName,
    planId: id,
  });
  const { data: materialMetadataSet } = useGetMaterialMetadataSetQuery({
    tenantName: tenantName,
    materialMetadataSetId: "default",
  });

  const [doCreateLoadMutation] = useCreateLoadMutation();
  const handleOnClickSteelGrade = async ({
    mixId,
    steelGradeId,
  }: {
    mixId: number;
    steelGradeId: number;
  }) => {
    await doCreateLoadMutation({
      tenantName,
      loadCreate: {
        steel_grade_id: steelGradeId,
        plan_id: id,
        mix_id: mixId,
      },
    });
  };

  const handleOnSubmit = useHandleOnSubmit(startAt, endAt, id, mixes);

  if (materialMetadataSet && planContext && planParameters) {
    const materialDecimalYields = typeSafeObjectFromEntries(
      Object.values(planContext.material_physics).map((material) => [
        material.material_id,
        material.yield_percent / 100,
      ])
    );
    const targetTappedMass =
      planParameters.physical_parameters.target_tapped_mass_lower;

    return (
      <EditableTable
        key={id}
        planId={id}
        startAt={startAt}
        endAt={endAt}
        mixes={mixes}
        defaultDRIMaterialId={defaultDRIMaterialId}
        materials={materialMetadataSet.materials.filter(
          (material) => material.visibility === "visible"
        )}
        materialDecimalYields={typeSafeObjectFromEntries(
          Object.values(planContext.material_physics).map((material) => [
            material.material_id,
            material.yield_percent / 100,
          ])
        )}
        materialPrices={null}
        steelGradeColumnWidth={350}
        targetTappedMass={
          planParameters.physical_parameters.target_tapped_mass_lower
        }
        steelGrades={typeSafeObjectFromEntries(
          planContext.steel_grades.map((steelGrade) => [
            steelGrade.id,
            steelGrade,
          ])
        )}
        onSteelGradeClick={handleOnClickSteelGrade}
        onSubmit={handleOnSubmit(materialDecimalYields, targetTappedMass)}
        disableEdit={initialDeploymentTypeToDeploymentTypes.backup.includes(
          deploymentType
        )}
      />
    );
  } else {
    return <DataGridSkeleton />;
  }
};

export const createPlanMixUpdates = (
  originalMixes: NamedPlanMix[],
  newMixes: NamedPlanMix[],
  materialYieldsAsDecimal: Record<number, number>,
  targetTappedMass: number
): Record<string, PlanMixUpdate> => {
  const originalMixesById = typeSafeObjectFromEntries(
    originalMixes.map((mix) => [mix.mix_id, mix])
  );
  const editedMixes = newMixes.filter((mix) => {
    const originalMix = originalMixesById[mix.mix_id];
    if (originalMix === undefined) {
      return true;
    } else {
      return !isMixEqual(mix, originalMix);
    }
  });
  return editedMixes.reduce((mixesUpdate, mix) => {
    const {
      mix_number,
      steel_grade_ids,
      product_group_id,
      period,
      baskets,
      dri,
      number_of_heats,
      mix_id,
    } = mix;

    const cleanedBaskets = cleanBaskets(baskets);

    const newDRI =
      dri !== null
        ? {
            mass: Math.max(
              0,
              getDRIMass(
                Object.values(mix.baskets).reduce(
                  (acc, basket) => sumRecords(basket.material_masses, acc),
                  {}
                ),
                materialYieldsAsDecimal,
                targetTappedMass,
                materialYieldsAsDecimal[dri.id]!
              )
            ),
            id: dri.id,
          }
        : null;

    return {
      ...mixesUpdate,
      [mix_id]: {
        mix_number,
        steel_grade_ids,
        product_group_id,
        period,
        baskets: cleanedBaskets,
        dri: newDRI && newDRI.mass > 0 ? newDRI : null,
        number_of_heats,
      },
    };
  }, {});
};
