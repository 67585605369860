import { CloudDownload } from "@mui/icons-material";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { Period } from "hooks/periodIndex";
import { useDownloadPlanMixes } from "src/hooks/useDownloadPlanMixes";
import { useExcelName } from "src/hooks/useExcelDownloadName";
import { PlanId } from "components/common/boundary/PlanId";

type Props = {
  doClose: () => void;
  planId: PlanId;
};

export const Download = ({ doClose, planId }: Props) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();
  const fileName = useExcelName();

  const downloadPlanMixes = useDownloadPlanMixes(planId, 1 as Period);

  return (
    <Stack
      sx={{
        justifyContent: "center",
        height: "100%",
        margin: "0 auto",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Stack sx={{ gap: 1, alignItems: "center" }}>
        <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
          {t("Download Excel")}
        </Typography>
        <Button
          onClick={downloadPlanMixes}
          variant="outlined"
          startIcon={<CloudDownload />}
        >
          {fileName}
        </Button>
      </Stack>
      <Typography
        variant="body1"
        sx={{ textTransform: "lowercase", color: theme.palette.text.secondary }}
      >
        {t("or")}
      </Typography>
      <Button variant="contained" onClick={doClose}>
        {t("close")}
      </Button>
    </Stack>
  );
};
