import equal from "fast-deep-equal";
import { z } from "zod";
import { NamedPlanMix, PlanMixBasket } from "src/store/api/generatedApi";
import { typeSafeObjectEntries } from "src/utils/typeSafeObjectEntries";
import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";
import { typeSafeObjectKeys } from "src/utils/typeSafeObjectKeys";

export const TableBasketNumberSchema = z.union([z.literal(2), z.literal(3)]);
export const TableProfileBasketNumberSchema = z.literal(1);

export const getDRIMass = (
  materialMasses: Record<number, number>,
  materialYieldsAsDecimal: Record<number, number>,
  targetTappedMass: number,
  driYieldAsDecimal: number
) => {
  if (driYieldAsDecimal < 0 || driYieldAsDecimal > 1) {
    throw new Error("DRI yield not gte 0 and lte 1");
  }
  if (
    Object.values(materialYieldsAsDecimal).some(
      (materialYield) => materialYield < 0 || materialYield > 1
    )
  ) {
    throw new Error("Material yield not gte 0 and lte 1");
  }
  return (
    (1 / driYieldAsDecimal) *
    (targetTappedMass -
      typeSafeObjectEntries(materialMasses).reduce(
        (total, [materialId, mass]) =>
          total + mass * materialYieldsAsDecimal[materialId]!,
        0
      ))
  );
};

const cleanMaterialMasses = (materialMasses: Record<string, number>) => {
  return Object.fromEntries(
    Object.entries(materialMasses).filter(([, value]) => value !== 0)
  );
};

const cleanSingleBasket = (planMixBasket: PlanMixBasket) => {
  return {
    material_masses: cleanMaterialMasses(planMixBasket.material_masses),
    total_mass: planMixBasket.total_mass,
  };
};

export const cleanBaskets = (baskets: Record<string, PlanMixBasket>) => {
  const basketsWithMass = Object.fromEntries(
    Object.entries(baskets).filter(
      ([, basket]) =>
        Object.entries(cleanMaterialMasses(basket.material_masses)).length > 0
    )
  );
  return Object.fromEntries(
    Object.entries(basketsWithMass).map(([key, basket]) => [
      key,
      cleanSingleBasket(basket),
    ])
  );
};

const orderSteelGradeIds = (ids: number[]) => ids.slice(0).sort();

export const isMixEqual = (mixA: NamedPlanMix, mixB: NamedPlanMix) => {
  return equal(
    {
      ...mixA,
      baskets: cleanBaskets(mixA.baskets),
      totals: cleanSingleBasket(mixA.totals),
      steel_grade_ids: orderSteelGradeIds(mixA.steel_grade_ids),
    },
    {
      ...mixB,
      baskets: cleanBaskets(mixB.baskets),
      totals: cleanSingleBasket(mixB.totals),
      steel_grade_ids: orderSteelGradeIds(mixB.steel_grade_ids),
    }
  );
};

export const areMixesEqual = (
  mixesA: NamedPlanMix[],
  mixesB: NamedPlanMix[]
) => {
  const byMixIdA = typeSafeObjectFromEntries(
    mixesA.map((mix) => [mix.mix_id, mix])
  );
  const byMixIdB = typeSafeObjectFromEntries(
    mixesB.map((mix) => [mix.mix_id, mix])
  );
  if (!equal(Object.keys(byMixIdA).sort(), Object.keys(byMixIdB).sort())) {
    // Short circuit if the mix IDs are not the same
    return false;
  }
  return typeSafeObjectKeys(byMixIdA).every((mixId) => {
    const mixA = byMixIdA[mixId];
    const mixB = byMixIdB[mixId];
    if (mixA === undefined || mixB === undefined) {
      return false;
    } else {
      return isMixEqual(mixA, mixB);
    }
  });
};
