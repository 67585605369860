import React from "react";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";

type HotkeyToggles = {
  areHotkeysEnabled: boolean;
  setAreHotkeysEnabled: (areHotkeysEnabled: boolean) => void;
};

const HotkeyToggleContext = createContext<HotkeyToggles>({
  areHotkeysEnabled: true,
  setAreHotkeysEnabled: () => null,
});

export const HotkeyToggleProvider = ({ children }: { children: ReactNode }) => {
  const [areHotkeysEnabled, setAreHotkeysEnabled] = useState(true);

  const hotkeyToggles = useMemo(() => {
    return {
      areHotkeysEnabled,
      setAreHotkeysEnabled,
    };
  }, [areHotkeysEnabled, setAreHotkeysEnabled]);

  return (
    <HotkeyToggleContext.Provider value={hotkeyToggles}>
      {children}
    </HotkeyToggleContext.Provider>
  );
};

const useSetAreHotkeysEnabled = () => {
  const { setAreHotkeysEnabled } = useContext(HotkeyToggleContext);
  return setAreHotkeysEnabled;
};

export const DisableHotkeysInChildren = ({
  children,
}: {
  children: ReactNode;
}) => {
  const setAreHotkeysEnabled = useSetAreHotkeysEnabled();

  React.useEffect(() => {
    setAreHotkeysEnabled(false);
    return () => {
      setAreHotkeysEnabled(true);
    };
  }, [setAreHotkeysEnabled]);

  return children;
};

export const useAreHotkeysEnabled = () => {
  const { areHotkeysEnabled } = useContext(HotkeyToggleContext);
  return areHotkeysEnabled;
};
