import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import {
  useNavigateToPlan,
  useNavigateToSearch,
  useNavigateToSearchPlanPeek,
} from "./navigation";
import { useSearchId } from "./search";
import { useSearch } from "contexts/search/provider";
import { usePlanId } from "components/common/boundary/PlanId";
import { Panel } from "src/components/search/SearchPage";
import { useAreHotkeysEnabled } from "contexts/hotkeys";

export type Hotkeys = {
  previousPeriod: string;
  nextPeriod: string;
  openPlan: string;
  closePlan: string;
  expandPlan: string;
  planProductionTab: string;
  planInventoryTab: string;
  transposePlanTable: string;
  openProductionInput: string;
  openInventoryInput: string;
  openAvailabilityInput: string;
  debugPage: string;
  historyPage: string;
  constraintsPage: string;
};

/**
 * Update locally with:
 *
 * > localStorage.hotkeys = JSON.stringify(
 * >   {...JSON.parse(localStorage.hotkeys ?? "{}"), nextPeriod: "shift+n"}
 * > )
 *
 * (Don't forget to remove the leading characters on each line.)
 */
export const hotkeys: Hotkeys = {
  ...{
    previousPeriod: ",",
    nextPeriod: ".",
    openPlan: "1,2,3,4,5,6,7,8,9,0",
    closePlan: "escape",
    expandPlan: "enter",
    planProductionTab: "p",
    planInventoryTab: "i",
    transposePlanTable: "t",
    openProductionInput: "a",
    openInventoryInput: "s",
    openAvailabilityInput: "d",
    debugPage: "shift+d",
    historyPage: "shift+h",
    constraintsPage: "shift+c",
  },
  ...(JSON.parse((localStorage.hotkeys ?? "{}") as string) as Hotkeys),
};

export const useSearchPageHotkeys = (
  panel: Panel,
  setPanel: (panel: Panel) => void
) => {
  const {
    results: { results },
  } = useSearch();
  const searchId = useSearchId();
  const navigateToSearchPlanPeek = useNavigateToSearchPlanPeek();
  const navigateToSearch = useNavigateToSearch();

  const enabled = useAreHotkeysEnabled();

  useHotkeys(
    hotkeys.openPlan,
    (e) => {
      const plans =
        results.status === "success" ? results.data?.plans ?? [] : [];
      const index = Number(e.key);
      if (Number.isInteger(index)) {
        const plan = plans[index === 0 ? 9 : index - 1];
        if (plan && searchId !== null) {
          navigateToSearchPlanPeek(searchId, plan.id);
        }
      }
    },
    { enabled }
  );

  useHotkeys(
    hotkeys.closePlan,
    () => {
      // Pressing escape when there's an open plan should close the plan, unless
      // an input modal is open on top of the plan
      if (panel !== Panel.None) {
        setPanel(Panel.None);
      } else if (searchId !== null) {
        navigateToSearch(searchId);
      }
    },
    { enabled }
  );

  useHotkeys(hotkeys.openProductionInput, () => setPanel(Panel.Production), {
    enabled,
  });
  useHotkeys(hotkeys.openInventoryInput, () => setPanel(Panel.Inventory), {
    enabled,
  });
  useHotkeys(
    hotkeys.openAvailabilityInput,
    () => setPanel(Panel.Availabilities),
    { enabled }
  );
};

export const usePlanPeekHotKeys = () => {
  const searchId = useSearchId();
  const planId = usePlanId();
  const navigateToPlan = useNavigateToPlan();
  const enabled = useAreHotkeysEnabled();

  useHotkeys(
    hotkeys.expandPlan,
    () => {
      if (searchId !== null) {
        navigateToPlan(planId, searchId);
      }
    },
    { enabled }
  );
};

export const usePlanPageTabIndexHotKeys = (): [
  number,
  React.Dispatch<React.SetStateAction<number>>,
] => {
  const enabled = useAreHotkeysEnabled();
  const [tabIndex, setTabIndex] = React.useState(0);
  useHotkeys(
    hotkeys.planProductionTab,
    () => {
      setTabIndex(0);
    },
    { enabled }
  );
  useHotkeys(
    hotkeys.planInventoryTab,
    () => {
      setTabIndex(1);
    },
    { enabled }
  );
  return [tabIndex, setTabIndex];
};

export const useToggleHotkey = (
  hotkey: string
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [isOn, setIsOn] = React.useState(false);
  const enabled = useAreHotkeysEnabled();
  useHotkeys(
    hotkey,
    () => {
      setIsOn((isOn) => !isOn);
    },
    { enabled }
  );
  return [isOn, setIsOn];
};

export const useProductionContextTransposeHotkey = () =>
  useToggleHotkey(hotkeys.transposePlanTable);
