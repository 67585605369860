import { Typography, useTheme } from "@mui/material";
import { useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";

type Props = {
  value: number;
};

export const MixPriceCell = ({ value }: Props) => {
  const { format: priceFormat } = useNumberSerialiser({
    decimalPlaces: 2,
    min: 0,
  });
  const unit = useUnitsFormatter(false);
  const theme = useTheme();
  return (
    <Typography component="span" variant="body2Mono">
      {priceFormat(value)}
      <Typography
        component="span"
        variant="body2Mono"
        sx={{ color: theme.palette.text.disabled }}
      >
        {unit("specific_cost")}
      </Typography>
    </Typography>
  );
};
