import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { RootState } from "store/store.tsx";

type SteelGradeSearch = {
  planId: number;
  steelGradeIds: number[];
};

const steelGradeSearchAdapter = createEntityAdapter({
  selectId: (steelGradeSearch: SteelGradeSearch) => steelGradeSearch.planId,
});

const adapterSelectors = steelGradeSearchAdapter.getSelectors();

export const steelGradeSearchSlice = createSlice({
  name: "steelGradeSearch",
  initialState: steelGradeSearchAdapter.getInitialState(undefined, []),
  reducers: {
    setSteelGrades: (
      state,
      { payload: [planId, steelGradeIds] }: PayloadAction<[number, number[]]>
    ) => {
      steelGradeSearchAdapter.setOne(state, {
        planId,
        steelGradeIds,
      });
    },
    clear: (state, { payload: [planId] }: PayloadAction<[number, string]>) => {
      steelGradeSearchAdapter.removeOne(state, planId);
    },
    copy(
      state,
      { payload: [fromPlanId, toPlanId] }: PayloadAction<[number, number]>
    ) {
      const fromPlanSearchedGrades = adapterSelectors.selectById(
        state,
        fromPlanId
      );
      if (fromPlanSearchedGrades) {
        steelGradeSearchAdapter.setOne(state, {
          planId: toPlanId,
          steelGradeIds: fromPlanSearchedGrades.steelGradeIds,
        });
      }
    },
  },
});

const createAppSelector = createSelector.withTypes<RootState>();

export const selectSearchedSteelGradesIds = createAppSelector(
  [(state) => state.steelGradeSearch, (_, planId: number) => planId],
  (steelGradeSearch, planId) =>
    steelGradeSearchAdapter.getSelectors().selectById(steelGradeSearch, planId)
      ?.steelGradeIds ?? []
);
