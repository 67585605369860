import { LoadedContent } from "src/components/common/loading/loadedContent";
import { liftLoadedState, Loaded, mapLoadedUnpack } from "models/loaded";
import { InputModalContentLayout } from "../common/InputModalContentLayout";
import { InputModeSelector } from "./modeSelector";
import {
  usePhysicalParameters,
  useProductGroups,
  useSteelGrades,
  useChemistryGroups,
  useChemicalElements,
  useProductionPlan,
  ProductionPlan,
  useMiscParams,
} from "contexts/search/provider";
import { ToolsPanel } from "./tools/toolsPanel";
import { usePeriodIndex } from "hooks/periodIndex";
import { ProductionDependencies } from "./dependencies";
import { ProductGroupPlanEditor } from "./content/productGroupPlanEditor";
import { SteelGradePlanEditor } from "./content/steelGradePlanEditor";
import { useEffect, useState } from "react";
import {
  useGetProductGroupPlanItemsFromSteelGradePlanItemsMutation,
  useGetSteelGradePlanItemsFromProductGroupPlanItemsMutation,
} from "src/store/api/generatedApi";
import { useTenantTranslation } from "hooks/formatters";
import equal from "fast-deep-equal";
import { useTenant } from "hooks/settings";

export const ProductionEditor = () => {
  const periodTab = usePeriodIndex();
  const [productionPlan, setProductionPlan] = useProductionPlan(periodTab);
  const [physicalParameters] = usePhysicalParameters();
  const [miscParams] = useMiscParams();

  const productGroups = useProductGroups();
  const steelGrades = useSteelGrades();

  return (
    <LoadedContent
      data={liftLoadedState({
        productionPlan,
        productGroups,
        chemistryGroups: useChemistryGroups(),
        steelGrades,
        chemicalElements: useChemicalElements(),
        physicalParameters,
        miscParams,
      })}
    >
      {({
        productionPlan,
        productGroups,
        chemistryGroups,
        steelGrades,
        chemicalElements,
        physicalParameters,
        miscParams,
      }) => (
        <ProductionEditorContent
          productionPlan={productionPlan}
          setProductionPlan={(plan) => setProductionPlan(() => plan)}
          productGroups={productGroups}
          chemistryGroups={chemistryGroups}
          steelGrades={steelGrades}
          chemicalElements={chemicalElements}
          tappedMass={physicalParameters.target_tapped_mass_lower}
          tappedToBilletYield={physicalParameters.tapped_to_billet_yield}
          massDisplaySummaryType={miscParams.mass_display_summary_type}
        />
      )}
    </LoadedContent>
  );
};

export const ProductionEditorContent = ({
  productionPlan,
  setProductionPlan,
  productGroups,
  chemistryGroups,
  steelGrades,
  chemicalElements,
  tappedMass,
  tappedToBilletYield,
  massDisplaySummaryType,
}: ProductionDependencies) => {
  const [mode, setMode] = useState<"productGroup" | "steelGrade">(
    productionPlan.type === "empty" ? "productGroup" : productionPlan.type
  );
  const { t } = useTenantTranslation();
  const producedMassPerHeat =
    massDisplaySummaryType === "tapped"
      ? tappedMass
      : tappedMass * tappedToBilletYield;

  const convertedProductionPlan = useConvertedProductionPlan(
    productionPlan,
    steelGrades.id,
    productGroups.id
  );

  return (
    <InputModalContentLayout
      topPanel={
        <InputModeSelector
          mode={mode}
          setMode={setMode}
          convert={
            mode === productionPlan.type ||
            convertedProductionPlan.status !== "success" ||
            setProductionPlan === undefined ||
            productionPlan.type === "empty"
              ? null
              : {
                  message:
                    mode === "productGroup"
                      ? t("viewingSteelGradePlanAsProductGroups")
                      : t("viewingProductGroupPlanAsSteelGrades"),
                  doConversion: () =>
                    setProductionPlan(convertedProductionPlan.data),
                }
          }
        />
      }
      leftPanel={
        mode === "productGroup" ? (
          <ProductGroupPlanEditor
            productGroupItems={
              productionPlan.type === mode
                ? productionPlan.items
                : mapLoadedUnpack(convertedProductionPlan, (plan) =>
                    plan.type === "productGroup" ? plan.items : []
                  ) ?? []
            }
            steelGrades={steelGrades}
            setProductGroupItems={
              setProductionPlan
                ? (items) => setProductionPlan({ type: "productGroup", items })
                : () => null
            }
            productGroups={productGroups}
            fixedRows
            uniqueRowIds
            producedMassPerHeat={producedMassPerHeat}
            heatsDisabled={productionPlan.type === "steelGrade"}
            loading={
              productionPlan.type === "steelGrade" &&
              convertedProductionPlan.status !== "success"
            }
          />
        ) : (
          <SteelGradePlanEditor
            productGroups={productGroups}
            chemistryGroups={chemistryGroups}
            steelGradeItems={
              productionPlan.type === mode
                ? productionPlan.items
                : mapLoadedUnpack(convertedProductionPlan, (plan) =>
                    plan.type === "steelGrade" ? plan.items : []
                  ) ?? []
            }
            setSteelGradeItems={
              setProductionPlan
                ? (items) => setProductionPlan({ type: "steelGrade", items })
                : () => null
            }
            steelGrades={steelGrades}
            uniqueRowIds
            producedMassPerHeat={producedMassPerHeat}
            elements={chemicalElements}
            heatsDisabled={productionPlan.type === "productGroup"}
            fixedRows={productionPlan.type === "productGroup"}
            loading={
              productionPlan.type === "productGroup" &&
              convertedProductionPlan.status !== "success"
            }
          />
        )
      }
      rightPanel={
        <ToolsPanel
          productionPlan={productionPlan}
          productGroups={productGroups}
          steelGrades={steelGrades}
        />
      }
      removeLeftPanelOutline
    />
  );
};

const useConvertedProductionPlan = (
  productionPlan: ProductionPlan,
  steelGradeSetId: number,
  productGroupingId: number
): Loaded<ProductionPlan> => {
  const [cachedArgument, setCachedArgument] = useState<ProductionPlan | null>(
    null
  );
  const [cachedResult, setCachedResult] = useState<Loaded<ProductionPlan>>({
    status: "waiting",
  });

  const [convertProductGroups] =
    useGetSteelGradePlanItemsFromProductGroupPlanItemsMutation();
  const [convertSteelGrades] =
    useGetProductGroupPlanItemsFromSteelGradePlanItemsMutation();
  const tenant = useTenant();

  useEffect(() => {
    if (!equal(productionPlan, cachedArgument)) {
      setCachedArgument(productionPlan);
      setCachedResult({ status: "loading" });

      if (productionPlan.type === "steelGrade") {
        void convertSteelGrades({
          tenantName: tenant,
          productGroupingId,
          steelGradePlanItems: productionPlan.items,
        })
          .unwrap()
          .then((items) =>
            setCachedResult({
              status: "success",
              data: { type: "productGroup", items },
              fetching: false,
            })
          );
      } else if (productionPlan.type === "productGroup") {
        void convertProductGroups({
          tenantName: tenant,
          steelGradeSetId,
          productGroupPlanItems: productionPlan.items,
        })
          .unwrap()
          .then((items) =>
            setCachedResult({
              status: "success",
              data: { type: "steelGrade", items },
              fetching: false,
            })
          );
      } else {
        setCachedResult({
          status: "success",
          data: { type: "empty", items: [] },
          fetching: false,
        });
      }
    }
  }, [
    productionPlan,
    steelGradeSetId,
    productGroupingId,
    cachedArgument,
    setCachedArgument,
    setCachedResult,
    convertSteelGrades,
    convertProductGroups,
    tenant,
  ]);

  return cachedResult;
};
