import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useTenantTranslation } from "hooks/formatters";

import { Basket } from "./Basket";
import { useTenant, useUserDisplayName, useUserUid } from "hooks/settings";
import {
  LoadBasketLayerRead,
  useGetCraneAssignedBasketQuery,
  useUpdateLoadBasketStatusMutation,
} from "src/store/api/generatedApi";
import React from "react";
import { refreshMS } from "./utils";

type BodyProps = {
  steelGradeName: string;
  basketNumber: number;
  layers: LoadBasketLayerRead[];
  completed: boolean;
  onComplete: () => void;
  isCompleting: boolean;
  craneName: string;
};

export const CraneBasket = () => {
  const { t } = useTenantTranslation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  // This hook should be amended to handle the null case and throw an error
  const craneUserUid = useUserUid()!;
  const tenantName = useTenant();
  const displayName = useUserDisplayName();
  const assignedBasket = useGetCraneAssignedBasketQuery(
    {
      tenantName,
      craneUserUid,
    },
    { pollingInterval: refreshMS }
  );

  const [doLoadBasketStatusUpdate] = useUpdateLoadBasketStatusMutation();

  const handleOnComplete = React.useCallback(
    (loadBasketId: number) => async () => {
      setIsSubmitting(true);
      void (await doLoadBasketStatusUpdate({
        tenantName,
        loadBasketId,
        loadBasketStatusUpdate: {
          status: "filled",
        },
      }));
      await assignedBasket.refetch();
      setIsSubmitting(false);
    },
    [tenantName, craneUserUid]
  );

  if (
    assignedBasket.error &&
    "status" in assignedBasket.error &&
    assignedBasket.error.status === 404
  ) {
    return (
      <Stack gap={2} alignItems="center" padding={5}>
        <Typography variant="h1">{displayName}</Typography>
        <Typography variant="h1">{t("noBasket")}</Typography>
        <Typography variant="h6">{t("noBasketAssignedToCrane")}</Typography>
        <Typography variant="h6">
          {t("pleaseContactShiftSupervisor")}
        </Typography>
      </Stack>
    );
  } else if (assignedBasket.data) {
    return (
      <Body
        steelGradeName={assignedBasket.data.steel_grade_name}
        basketNumber={assignedBasket.data.basket_number}
        layers={assignedBasket.data.materials}
        completed={assignedBasket.data.status === "filled"}
        onComplete={handleOnComplete(assignedBasket.data.load_basket_id)}
        isCompleting={isSubmitting}
        craneName={assignedBasket.data.assigned_to_name ?? ""}
      />
    );
  } else {
    return <Skeleton />;
  }
};

const Body = ({
  steelGradeName,
  basketNumber,
  layers,
  completed,
  onComplete,
  isCompleting,
  craneName,
}: BodyProps) => {
  const { t } = useTenantTranslation();
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "200px 1fr 200px",
        gridTemplateRows: "repeat(3,max-content) 1fr",
        margin: 4,
        height: "calc(100% - 64px)",
        rowGap: 1,
      }}
    >
      <Typography
        variant="h1"
        sx={{ justifySelf: "center", alignSelf: "end", gridColumn: "2" }}
      >
        {craneName}
      </Typography>
      <Typography
        variant="h2"
        sx={{ justifySelf: "center", alignSelf: "end", gridColumn: 2 }}
      >
        {steelGradeName}
      </Typography>
      <LoadingButton
        variant="contained"
        sx={{
          justifySelf: "end",
          fontSize: 32,
          gridColumn: 3,
          gridRow: "1 / span 2",
        }}
        disabled={completed}
        loading={isCompleting}
        onClick={onComplete}
        size="large"
      >
        {t("complete")}
      </LoadingButton>

      <Typography
        variant="h5"
        sx={{ justifySelf: "center", alignSelf: "end", gridColumn: 2 }}
      >
        {t("basket")}&nbsp;{basketNumber}
      </Typography>
      <Box
        sx={{
          gridColumn: "1/-1",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          paddingBottom: 12,
        }}
      >
        <Basket layers={layers} completed={completed} size="big" />
      </Box>
    </Box>
  );
};
