import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ValidatedTextField } from "components/common/inputs/validatedTextField";
import { sorted } from "helpers";
import { useEffect, useState, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { LabelView } from "./label";
import { useLocalStorageState } from "./helpers";
import { VideoControls } from "./videoControls";
import { doLoaded, loadedEndpoint } from "models/loaded";
import {
  useReadVideoLabelsQuery,
  useWriteVideoLabelsMutation,
} from "src/store/api/generatedApi";
import { useTenant, useUserEmail } from "hooks/settings";
import equal from "fast-deep-equal";
import { useNotifyStatus } from "contexts/status";
import { v4 } from "uuid";
import { clamp } from "hooks/serialisers/numbers";

export type Label = { start: number; end: number } & (
  | { type: "lift"; material: string | null }
  | { type: "drop" }
  | { type: "obscured" }
);

export const LabelVideo = ({
  videoKey,
  controls,
  setStatusText,
  width,
  upsideDown,
  setUpsideDown,
}: {
  videoKey: string;
  controls: VideoControls;
  setStatusText: (statusText: string | null) => void;
  width: number;
  upsideDown: boolean;
  setUpsideDown: (upsideDown: boolean) => void;
}) => {
  const {
    clientState,
    edited,
    addLabel,
    deleteLabel,
    deleteLastLabel,
    saveLabels,
    discardChanges,
    markVideoInvalid,
  } = useLabelsState(videoKey, upsideDown, setUpsideDown);

  const saveLabelsRef = useRef(saveLabels);
  useEffect(() => {
    saveLabelsRef.current = saveLabels;
  }, [saveLabels]);

  const controlsRef = useRef(controls);
  useEffect(() => {
    controlsRef.current = controls;
  }, [controls]);

  const editedRef = useRef(edited);
  useEffect(() => {
    editedRef.current = edited;
  }, [edited]);

  useEffect(() => {
    return () => {
      if (editedRef.current) {
        void saveLabelsRef.current(videoKey, controlsRef.current.totalFrames);
      }
    };
  }, [videoKey]);

  useMaterialHotkey("u", null, addLabel, controls, setStatusText);

  useDropHotkey(addLabel, controls, setStatusText);
  useObscuredHotkey(addLabel, controls, setStatusText);

  useHotkeys("backspace", () => deleteLastLabel(controls.frameIndex));
  useHotkeys("shift+backspace", () => {
    const label = deleteLastLabel(controls.frameIndex);
    if (label !== null && controls !== null) {
      controls.pause();
      controls.setFrameIndex(label.start);
    }
  });

  return (
    <Stack>
      <VideoTimeline width={width} controls={controls} events={clientState} />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography>
          Edited? {edited ? "Yes. Changes will autosave" : "No"}
        </Typography>
        <Button onClick={() => saveLabels(videoKey, controls.totalFrames)}>
          Force save
        </Button>
        <Button onClick={() => markVideoInvalid(videoKey)}>
          Invalid video
        </Button>
        {edited ? (
          <Button onClick={discardChanges}>Discard changes</Button>
        ) : null}
      </Stack>

      <Accordion
        slotProps={{ transition: { unmountOnExit: true, timeout: 200 } }}
      >
        <AccordionSummary>
          <Typography>Events</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            {sorted(clientState, (event) => -event.start).map((event) => (
              <LabelView
                key={event.start}
                label={event}
                deleteLabel={deleteLabel}
                seekTimestamp={controls.setFrameIndex}
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion slotProps={{ transition: { timeout: 200 } }}>
        <AccordionSummary>
          <Typography>Aliases</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" sx={{ width: "100%" }}>
            <Stack>
              <Aliases
                addLabel={addLabel}
                controls={controls}
                setStatusText={setStatusText}
              />
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

const VideoTimeline = ({
  width,
  controls,
  events,
}: {
  width: number;
  controls: VideoControls;
  events: Label[];
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const height = 200;
  const theme = useTheme();

  return (
    <Stack
      ref={ref}
      sx={{
        width,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 4,
        backgroundColor: "#000000bb",
        height,
        cursor: "pointer",
        position: "relative",
      }}
      direction="row"
      justifyContent="center"
      onClick={(event) => {
        if (!ref.current) {
          return;
        }

        const { left, top } = ref.current.getBoundingClientRect();
        const mouse = { x: event.clientX - left, y: event.clientY - top };

        controls.setFrameIndex(controls.totalFrames * (mouse.x / width));
      }}
    >
      {events.map((event) => (
        <Box
          key={event.start.toString()}
          sx={{
            backgroundColor: event.type === "drop" ? "red" : "pink",
            opacity: 0.6,
            position: "absolute",
            left: (event.start / controls.totalFrames) * width,
            top: 40,
            bottom: 20,
            width: clamp(
              (event.end - event.start) * (width / controls.totalFrames),
              2,
              null
            ),
          }}
        />
      ))}

      <Box
        sx={{
          backgroundColor: "white",
          position: "absolute",
          left: (controls.frameIndex / controls.totalFrames) * width,
          top: 30,
          bottom: 10,
          width: 2,
        }}
      />
      <Typography
        sx={{
          position: "relative",
          left:
            (controls.frameIndex / controls.totalFrames) * width - width / 2,
          color: theme.palette.common.white,
          textAlign: "left",
          top: 5,
        }}
      >
        {Math.round(controls.frameIndex)}/{Math.round(controls.totalFrames)}
      </Typography>
    </Stack>
  );
};

const Aliases = ({
  addLabel,
  controls,
  setStatusText,
}: {
  addLabel: (label: Label) => void;
  controls: VideoControls;
  setStatusText: (statusText: string | null) => void;
}) => {
  const [aliases, setAliases] = useLocalStorageState<{ [key: string]: string }>(
    "videoLabellingAliases",
    {
      s: "Shredded",
      w: "Turnings",
      a: "#1 Heavy Melt",
      r: "Rebar",
      q: "Tire Wire",
      d: "Reclaim",
      x: "Cast Iron Borings",
      z: "Tank Tracks",
      e: "#2 Heavy Melt",
      c: "Billets",
      f: "P & S",
      t: "Wire Bundles",
      v: "Flashings",
      g: "Rail Crops",
      b: "#1 & #2 Heavy Melting Steel",
    }
  );

  return (
    <>
      <ValidatedTextField<{ [key: string]: string }>
        value={aliases}
        setValue={(newValue) => setAliases(newValue)}
        serialiser={{
          format: (value) => JSON.stringify(value, null, 2),
          parse: (text) => ({
            valid: true,
            value: JSON.parse(text) as { [key: string]: string },
          }),
        }}
        textFieldProps={{ multiline: true }}
      />

      {Object.entries(aliases).map(([hotkey, material]) => (
        <Alias
          key={hotkey}
          hotkey={hotkey}
          material={material}
          addLabel={addLabel}
          controls={controls}
          setStatusText={setStatusText}
        />
      ))}
    </>
  );
};

const Alias = ({
  hotkey,
  material,
  addLabel,
  controls,
  setStatusText,
}: {
  hotkey: string;
  material: string;
  addLabel: (label: Label) => void;
  controls: VideoControls;
  setStatusText: (statusText: string | null) => void;
}) => {
  useMaterialHotkey(hotkey, material, addLabel, controls, setStatusText);

  return null;
};

const useMaterialHotkey = (
  hotkey: string,
  material: string | null,
  addLabel: (label: Label) => void,
  controls: VideoControls,
  setStatusText: (statusText: string | null) => void
) => {
  const [start, setStart] = useState<number | null>(null);

  useKeyPressEffects(
    hotkey,
    () => {
      setStart(controls.getFrameIndex());
      setStatusText(material ?? "Unknown");
    },
    () => {
      if (start !== null) {
        addLabel({
          start,
          end: controls.getFrameIndex(),
          type: "lift",
          material,
        });
        setStart(null);
        setStatusText(null);
      }
    }
  );
};

const useDropHotkey = (
  addLabel: (label: Label) => void,
  controls: VideoControls,
  setStatusText: (statusText: string | null) => void
) => {
  const [start, setStart] = useState<number | null>(null);

  return useKeyPressEffects(
    "down",
    () => {
      setStart(controls.getFrameIndex());
      setStatusText("Drooping");
    },
    () => {
      if (start !== null) {
        addLabel({ start, end: controls.getFrameIndex(), type: "drop" });
        setStart(null);
        setStatusText(null);
      }
    }
  );
};

const useObscuredHotkey = (
  addLabel: (label: Label) => void,
  controls: VideoControls,
  setStatusText: (statusText: string | null) => void
) => {
  const [start, setStart] = useState<number | null>(null);

  return useKeyPressEffects(
    "/",
    () => {
      setStart(controls.getFrameIndex());
      setStatusText("Obscured");
    },
    () => {
      if (start !== null) {
        addLabel({ start, end: controls.getFrameIndex(), type: "obscured" });
        setStart(null);
        setStatusText(null);
      }
    }
  );
};

const useKeyPressEffects = (
  key: string,
  onDown: () => void,
  onUp: () => void
) => {
  const [pressed, setPressed] = useState(false);
  useHotkeys(
    key,
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!pressed) {
        onDown();
      }
      setPressed(true);
    },
    { keydown: true }
  );
  useHotkeys(
    key,
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onUp();
      setPressed(false);
    },
    { keyup: true }
  );
};

const useLabelsState = (
  videoKey: string,
  upsideDown: boolean,
  setUpsideDown: (upsideDown: boolean) => void
) => {
  const tenant = useTenant();
  const [writeVideoLabels] = useWriteVideoLabelsMutation();
  const user = (useUserEmail() ?? "unknownuser").split("@")[0]!;

  const notifyStatus = useNotifyStatus();

  const serverState = loadedEndpoint(
    useReadVideoLabelsQuery({ tenantName: tenant, videoKey })
  );
  const [clientState, setClientState] = useState<Label[]>([]);

  useEffect(() => {
    doLoaded(serverState, (state) => {
      setClientState((state?.labels ?? []) as Label[]);
      setUpsideDown(Boolean(state?.upside_down));
    });
  }, [serverState.status]);

  const edited =
    serverState.status === "success" &&
    clientState.length > 0 &&
    !equal(serverState.data?.labels, clientState);

  const addLabel = (label: Label) => {
    if (label.end > label.start) {
      setClientState((current) =>
        sorted(
          [
            // Remove any labels overlapping with this one
            ...current.filter(
              (item) => item.end < label.start || label.end < item.start
            ),
            label,
          ],
          (item) => item.start
        )
      );
    }
  };

  const deleteLabel = (label: Label) =>
    setClientState((current) =>
      current.filter((item) => item.start !== label.start)
    );

  const deleteLastLabel = (frameIndex: number): Label | null => {
    const eligibleEvents = clientState.filter(
      (event) => event.start < frameIndex
    );
    const eventToDelete = eligibleEvents[eligibleEvents.length - 1];

    if (eventToDelete !== undefined) {
      deleteLabel(eventToDelete);
      return eventToDelete;
    } else {
      return null;
    }
  };

  const saveLabels = (key: string, duration: number) => {
    return writeVideoLabels({
      tenantName: tenant,
      videoLabels: {
        key,
        user,
        description: v4().toString().split("-").join(""),
        labels: clientState,
        duration,
        upside_down: upsideDown,
      },
    })
      .unwrap()
      .then((path) => {
        notifyStatus({
          type: "success",
          text: `Saved ${clientState.length} labels (${path})`,
        });
        return path;
      })
      .catch(() =>
        notifyStatus({ type: "error", text: "Failed to save labels" })
      );
  };

  const markVideoInvalid = (key: string) => {
    return writeVideoLabels({
      tenantName: tenant,
      videoLabels: {
        key,
        user,
        description: v4().toString().split("-").join(""),
        labels: clientState,
        duration: 0,
        invalid: true,
      },
    })
      .unwrap()
      .then((path) => {
        notifyStatus({
          type: "success",
          text: `Saved ${clientState.length} labels (${path})`,
        });
        return path;
      })
      .catch(() =>
        notifyStatus({ type: "error", text: "Failed to save labels" })
      );
  };

  const discardChanges = () =>
    doLoaded(serverState, (state) =>
      setClientState((state?.labels ?? []) as Label[])
    );

  return {
    clientState,
    setClientState,
    edited,
    addLabel,
    deleteLabel,
    deleteLastLabel,
    saveLabels,
    discardChanges,
    markVideoInvalid,
  };
};
