import React from "react";
import {
  Box,
  Chip,
  Modal,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import {
  Crane,
  LoadBasketRead,
  useGetLoadQuery,
  useUpdateLoadBasketAssignmentMutation,
} from "src/store/api/generatedApi";

import { useTenantTranslation } from "hooks/formatters";
import { useTenant } from "hooks/settings";

import { Basket } from "./Basket";
import { refreshMS } from "./utils";

type BodyProps = {
  baskets: LoadBasketRead[];
  cranes: Crane[];
  steelGrade: string;
  refetch: ReturnType<typeof useGetLoadQuery>["refetch"];
};

type WrapperProps = { loadId: number; cranes: Crane[] };

type Props = {
  onClose: () => void;
  loadId: number | null;
  cranes: Crane[];
};

const SkeletonBody = () => {
  return <Skeleton />;
};

const Body = ({ baskets, cranes, steelGrade, refetch }: BodyProps) => {
  const [isAssigning, setIsAssigning] = React.useState(false);

  const theme = useTheme();
  const { t } = useTenantTranslation();
  const tenantName = useTenant();

  const [updateLoadBasketAssignment] = useUpdateLoadBasketAssignmentMutation();

  const handleCraneChipClick = React.useCallback(
    async (loadBasketId: number, craneUID: string | null) => {
      setIsAssigning(true);
      void (await updateLoadBasketAssignment({
        tenantName,
        loadBasketId,
        loadBasketAssignmentUpdate: { assigned_to_uid: craneUID },
      }));
      await refetch();
      setIsAssigning(false);
    },
    [updateLoadBasketAssignment, tenantName, setIsAssigning, refetch]
  );

  return (
    <Stack gap={1}>
      <Typography variant="h4">{steelGrade}</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: 5,
        }}
      >
        {baskets.map((basket) => {
          const isActive = basket.status === "partially_filled";
          const isComplete = basket.status === "filled";

          return (
            <Stack
              key={basket.basket_number}
              sx={{
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: theme.palette.divider,
                boxShadow: theme.shadows[1],
                borderRadius: 1,
                paddingTop: 1,
                paddingX: 2,
                paddingBottom: 4,
                rowGap: 2,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5">
                  {t("basket")} {basket.basket_number}
                </Typography>
                {isActive ? <Chip label="active" color="success" /> : null}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gridColumn: 1,
                  gap: 0.5,
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  sx={{ flexBasis: "100%", opacity: isComplete ? 0.5 : 1 }}
                  variant="subtitle2"
                >
                  {t("assignedTo")}
                </Typography>
                {cranes
                  .slice(0)
                  .sort((craneA, craneB) =>
                    craneA.name > craneB.name
                      ? 1
                      : craneB.name > craneA.name
                      ? -1
                      : 0
                  )
                  .map((crane) => {
                    const isAssignedToBasket =
                      basket.status !== "filled" &&
                      basket.assigned_to_uid === crane.user_uid;
                    return (
                      <Chip
                        key={crane.user_uid}
                        label={crane.name}
                        disabled={isActive || isComplete || isAssigning}
                        color={isAssignedToBasket ? "primary" : "default"}
                        onClick={() => {
                          if (isAssignedToBasket) {
                            void handleCraneChipClick(
                              basket.load_basket_id,
                              null
                            );
                          } else {
                            void handleCraneChipClick(
                              basket.load_basket_id,
                              crane.user_uid
                            );
                          }
                        }}
                      />
                    );
                  })}
              </Box>
              <Basket
                key={basket.basket_number}
                sx={{ gridColumn: "1/-1" }}
                size="medium"
                layers={basket.materials}
                completed={isComplete}
              />
            </Stack>
          );
        })}
      </Box>
    </Stack>
  );
};

const Wrapper = ({ loadId, cranes }: WrapperProps) => {
  const tenantName = useTenant();
  const load = useGetLoadQuery(
    { tenantName, loadId },
    { pollingInterval: refreshMS }
  );
  if (load.data) {
    return (
      <Body
        baskets={Object.values(load.data.baskets)}
        cranes={cranes}
        steelGrade={load.data.steel_grade_name}
        refetch={load.refetch}
      />
    );
  }
  return <SkeletonBody />;
};

export const LoadModal = ({ onClose, loadId, cranes }: Props) => {
  return (
    <Modal open={loadId !== null} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          minHeight: 600,
          minWidth: 150,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: 2,
        }}
      >
        {loadId === null ? null : <Wrapper loadId={loadId} cranes={cranes} />}
      </Box>
    </Modal>
  );
};
