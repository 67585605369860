import React from "react";
import {
  Box,
  Chip,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  DataGridPremium,
  GridApi,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { z } from "zod";

import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import {
  MaterialRead,
  NamedPlanMix,
  PlanMixBasket,
  SteelGrade,
} from "src/store/api/generatedApi";
import { sumRecords } from "src/utils/sumRecords";
import {
  getDRIMass,
  TableBasketNumberSchema,
  TableProfileBasketNumberSchema,
} from "./utils";
import { MixNumberCell } from "./MixNumberCell";
import { MixPriceCell } from "./MixPriceCell";

type TableBasketNumber = z.infer<typeof TableBasketNumberSchema>;
type TableProfileBasketNumber = z.infer<typeof TableProfileBasketNumberSchema>;
type Dri = {
  id: number;
  mass: number;
};

type TotalRow = {
  id: number;
  type: "total";
  steelGrades: number[];
  mixId: number;
  copperGroup: number;
  dri: Dri | null;
};

type BasketRow = {
  id: number;
  type: "basket";
  copperGroup: number;
  steelGrades: number[];
  materialMasses: Record<number, number>;
  mixId: number;
  basketNumber: TableBasketNumber;
  dri: Dri | null;
};

type EmptyBasketRow = {
  id: number;
  type: "empty_basket";
  mixId: number;
  copperGroup: number;
  steelGrades: number[];
  basketNumber: TableBasketNumber;
  dri: Dri | null;
};

type ProfileRow = {
  id: number;
  type: "profile";
  copperGroup: number;
  steelGrades: number[];
  selectedSteelGrades: number[];
  materialMasses: Record<number, number>;
  mixId: number;
  dri: Dri | null;
};

type EmptyProfileRow = {
  id: number;
  type: "empty_profile";
  mixId: number;
  copperGroup: number;
  steelGrades: number[];
  selectedSteelGrades: number[];
  basketNumber: TableProfileBasketNumber;
  materialMasses: Record<number, number>;
  dri: Dri | null;
};

export type Row =
  | ProfileRow
  | BasketRow
  | TotalRow
  | EmptyBasketRow
  | EmptyProfileRow;

type SteelGradesCellProps = {
  selectedSteelGradeIds: number[];
  steelGrades: SteelGrade[];
  onClick?: (id: number) => void;
};

type Props = {
  disableEdit: boolean;
  mixes: NamedPlanMix[];
  materials: MaterialRead[];
  defaultDRIMaterialId: number;
  materialDecimalYields: Record<number, number>;
  steelGradeColumnWidth: number;
  targetTappedMass: number;
  steelGrades: Record<number, SteelGrade>;
  selectedSteelGrades: number[];
  apiRef: React.RefObject<GridApiPremium>;
  onSteelGradeClick?: (midId: number) => (id: number) => void;
  onEdit: (mixId: number, editedBaskets: Record<string, PlanMixBasket>) => void;
  materialPrices: Record<number, number | null> | null;
};

type BaseEmptyBasketWeightCellProps = {
  onSubmit: (mixId: number) => void;
  id: number;
  copperGroup: number;
  steelGrades: number[];
  dri: Dri | null;
  mixId: number;
};

type EmptyBasketWeightCellProps = BaseEmptyBasketWeightCellProps & {
  type: "basket";
  basketNumber: TableBasketNumber;
};
type EmptyProfileWeightCellProps = BaseEmptyBasketWeightCellProps & {
  type: "profile";
  selectedSteelGrades: number[];
  basketNumber: TableProfileBasketNumber;
};

type BaseBasketWeightCellProps = {
  onSubmit: (mixId: number) => void;
  id: number;
  copperGroup: number;
  steelGrades: number[];
  dri: Dri | null;
  mixId: number;
  value: number | null;
};

type BasketWeightCellProps = BaseBasketWeightCellProps & {
  type: "basket";
  basketNumber: TableBasketNumber;
};
type ProfileWeightCellProps = BaseBasketWeightCellProps & {
  type: "profile";
  selectedSteelGrades: number[];
  basketNumber: TableProfileBasketNumber;
};

const SteelGradesCell = ({
  steelGrades,
  onClick,
  selectedSteelGradeIds,
}: SteelGradesCellProps) => {
  const theme = useTheme();

  const handleOnClick = React.useMemo(() => {
    if (onClick !== undefined) {
      return (id: number) => onClick(id);
    } else {
      return undefined;
    }
  }, [onClick]);

  const [selectedSteelGrades, notSelectedSteelGrades] = React.useMemo(() => {
    return [
      steelGrades.filter((steelGrade) =>
        selectedSteelGradeIds.includes(steelGrade.id)
      ),
      steelGrades.filter(
        (steelGrade) => !selectedSteelGradeIds.includes(steelGrade.id)
      ),
    ] as const;
  }, [selectedSteelGradeIds, steelGrades]);

  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: 0.4,
        flexWrap: "wrap",
        paddingY: 1,
        paddingX: 0.5,
        overflow: "auto",
        height: `calc(100% - ${theme.spacing(2)})`,
        alignItems: "flex-start",
      }}
    >
      {selectedSteelGrades.map((steelGrade) => (
        <Chip
          key={steelGrade.id}
          label={steelGrade.name}
          size="small"
          onClick={
            handleOnClick ? () => handleOnClick(steelGrade.id) : undefined
          }
          sx={{
            backgroundColor: theme.palette.grey[600],
            userSelect: "none",
            color: theme.palette.common.white,
          }}
        />
      ))}
      {selectedSteelGrades.length > 0 ? (
        <Box sx={{ flexBasis: "100%", height: theme.spacing(0.5) }} />
      ) : null}
      {notSelectedSteelGrades.map((steelGrade) => (
        <Chip
          key={steelGrade.id}
          label={steelGrade.name}
          size="small"
          onClick={
            handleOnClick ? () => handleOnClick(steelGrade.id) : undefined
          }
          sx={{
            userSelect: "none",
          }}
        />
      ))}
    </Stack>
  );
};

const EmptyBasketWeightCell = ({
  onSubmit,
  id,
  copperGroup,
  steelGrades,
  dri,
  mixId,
  ...props
}: EmptyBasketWeightCellProps | EmptyProfileWeightCellProps) => {
  const apiRef = useGridApiContext();
  const handleOnClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const filledRow = ((): BasketRow | ProfileRow => {
      const { type } = props;
      switch (type) {
        case "basket": {
          const { basketNumber } = props;
          const basketRow: BasketRow = {
            id,
            type: "basket",
            copperGroup,
            steelGrades,
            dri,
            mixId,
            basketNumber,
            materialMasses: {},
          };
          return basketRow;
        }
        case "profile": {
          const { selectedSteelGrades } = props;
          const basketRow: ProfileRow = {
            id,
            type: "profile",
            copperGroup,
            steelGrades,
            selectedSteelGrades,
            mixId,
            dri,
            materialMasses: {},
          };
          return basketRow;
        }
      }
    })();
    apiRef.current.updateRows([filledRow]);
    onSubmit(mixId);
  };
  return (
    <IconButton sx={{ padding: 0 }} onClick={handleOnClick}>
      <AddCircleOutline fontSize="small" />
    </IconButton>
  );
};

const BasketWeightCell = ({
  onSubmit,
  id,
  copperGroup,
  steelGrades,
  dri,
  mixId,
  value,
  ...props
}: BasketWeightCellProps | ProfileWeightCellProps) => {
  const apiRef = useGridApiContext();
  const handleOnClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const emptyRow = (() => {
      const { type } = props;
      switch (type) {
        case "basket": {
          const { basketNumber } = props;
          const emptyBasketRow: EmptyBasketRow = {
            id,
            type: "empty_basket",
            copperGroup,
            steelGrades,
            dri,
            mixId,
            basketNumber,
          };
          return emptyBasketRow;
        }
        case "profile": {
          const { selectedSteelGrades, basketNumber } = props;
          const emptyProfileRow: EmptyProfileRow = {
            id,
            type: "empty_profile",
            copperGroup,
            steelGrades,
            selectedSteelGrades,
            dri,
            mixId,
            materialMasses: {},
            basketNumber,
          };
          return emptyProfileRow;
        }
      }
    })();
    apiRef.current.updateRows([emptyRow]);
    onSubmit(mixId);
  };
  return (
    <>
      <Typography variant="body1Mono">{value?.toFixed(0)}</Typography>
      <IconButton sx={{ padding: 0 }} onClick={handleOnClick}>
        <RemoveCircleOutline fontSize="small" />
      </IconButton>
    </>
  );
};
const sumMixRowMaterialMasses = (
  mixId: number,
  dataGridApi: React.MutableRefObject<GridRenderCellParams["api"]>
) => {
  return dataGridApi.current
    .getAllRowIds()
    .map((rowId) => dataGridApi.current.getRow<Row>(rowId))
    .filter(
      (dataGridRow: Row | null): dataGridRow is ProfileRow | BasketRow =>
        dataGridRow !== null &&
        mixId === dataGridRow.mixId &&
        (dataGridRow.type === "basket" || dataGridRow.type === "profile")
    )
    .map((row) => row.materialMasses)
    .reduce((total, materialMasses) => sumRecords(total, materialMasses), {});
};

const useMakeColumns = (
  materials: MaterialRead[],
  materialYields: Record<number, number>,
  steelGradeColumnWidth: number,
  targetTappedMass: number,
  steelGrades: Record<number, SteelGrade>,
  disableEdit: boolean,
  defaultDRIMaterialId: number,
  materialPrices: Record<number, number | null> | null,
  onRowChange: (mixId: number) => void,
  onSteelGradeClick?: (mixId: number) => (id: number) => void
): GridColDef<Row>[] => {
  const { t } = useTenantTranslation();
  const bracketUnits = useUnitsFormatter(true);
  const units = useUnitsFormatter(false);
  const columns = React.useMemo((): GridColDef<Row>[] => {
    const columnMaterials = materials
      .filter((material) => material.id !== defaultDRIMaterialId)
      .sort((a, b) => a.deployment_ordering - b.deployment_ordering);
    const columns: GridColDef<Row>[] = [
      {
        field: "mix_name",
        width: 90,
        headerName: materialPrices === null ? "#" : units("specific_cost"),
        valueGetter: (_, row, __, dataGridApi): string | number => {
          switch (row.type) {
            case "basket":
            case "total":
            case "empty_basket":
            case "empty_profile":
            case "profile": {
              if (materialPrices) {
                if (
                  dataGridApi.current &&
                  "getAllRowIds" in dataGridApi.current
                ) {
                  const [totalPrice, totalWeight] = dataGridApi.current
                    .getAllRowIds()
                    .map((rowId) => dataGridApi.current.getRow<Row>(rowId))
                    .filter(
                      (
                        dataGridRow: Row | null
                      ): dataGridRow is ProfileRow | BasketRow =>
                        dataGridRow !== null &&
                        row.mixId === dataGridRow.mixId &&
                        (dataGridRow.type === "basket" ||
                          dataGridRow.type === "profile")
                    )
                    .flatMap((row) => {
                      return [
                        ...Object.entries(row.materialMasses).map(
                          ([id, mass]) => [Number(id), mass] as [number, number]
                        ),
                        [
                          defaultDRIMaterialId,
                          getDRIMass(
                            sumMixRowMaterialMasses(row.mixId, dataGridApi),
                            materialYields,
                            targetTappedMass,
                            materialYields[defaultDRIMaterialId]!
                          ),
                        ] as [number, number],
                      ];
                    })
                    .reduce(
                      (
                        [totalPrice, totalWeight]: [number, number],
                        [materialId, materialMass]: [number, number]
                      ) => {
                        return [
                          totalPrice +
                            materialPrices[materialId]! * materialMass,
                          totalWeight + materialMass,
                        ];
                      },
                      [0, 0] as [number, number]
                    );
                  return totalPrice / totalWeight;
                }

                return "";
              } else {
                return row.mixId.toString();
              }
            }
          }
        },
        renderCell: (row: GridRenderCellParams<Row, number>) => {
          if (materialPrices && row.value !== undefined) {
            return <MixPriceCell value={row.value} />;
          } else {
            return <MixNumberCell mixNumber={row.row.mixId} />;
          }
        },
        cellClassName: "grey-50 bottom-row center",
        headerClassName: "grey-50",
      },
      {
        field: "steel_grades",
        headerName: t("steelGrades"),
        width: steelGradeColumnWidth,
        valueGetter: (_, row): number => {
          switch (row.type) {
            case "basket":
            case "total":
            case "empty_basket":
            case "empty_profile":
            case "profile": {
              return row.mixId;
            }
          }
        },
        renderCell: ({ row }) => {
          switch (row.type) {
            case "empty_profile":
            case "profile": {
              return (
                <SteelGradesCell
                  selectedSteelGradeIds={row.selectedSteelGrades}
                  steelGrades={row.steelGrades.map(
                    (steelGradeId) => steelGrades[steelGradeId]!
                  )}
                  onClick={
                    onSteelGradeClick ? onSteelGradeClick(row.mixId) : undefined
                  }
                />
              );
            }
            case "basket":
            case "empty_basket":
            case "total":
              return null;
          }
        },
        cellClassName: "grey-50 bottom-row",
        headerClassName: "grey-50",
      },
      {
        field: "copper_group",
        headerName: "Cu",
        width: 50,
        headerClassName: "warning-light",
        valueGetter: (_, row): number => {
          switch (row.type) {
            case "basket":
            case "total":
            case "empty_profile":
            case "empty_basket":
            case "profile": {
              return row.mixId;
            }
          }
        },
        valueFormatter: (_, row) => {
          return (row.copperGroup * 100).toFixed(0);
        },
        cellClassName: "warning-light bottom-row center",
      },
      {
        field: "dri_weight",
        headerName: `${t("dri")} ${bracketUnits("mass")}`,
        width: 50,
        headerClassName: "grey-200",
        valueGetter: (_, row): number => {
          switch (row.type) {
            case "basket":
            case "profile":
            case "empty_basket":
            case "empty_profile":
            case "total": {
              return row.mixId;
            }
          }
        },
        valueFormatter: (_, row, __, dataGridApi) => {
          if ("getAllRowIds" in dataGridApi.current) {
            const amount = getDRIMass(
              sumMixRowMaterialMasses(row.mixId, dataGridApi),
              materialYields,
              targetTappedMass,
              materialYields[defaultDRIMaterialId]!
            );
            return amount.toFixed(0);
          } else {
            return "";
          }
        },
        cellClassName: "grey-200 bottom-row center",
      },
      {
        field: "basket_weight",
        resizable: false,
        headerName: `${t("basket")} ${bracketUnits("mass")}`,
        width: 100,
        headerClassName: "grey-200 edge-column",
        renderCell: (
          rowParams: GridRenderCellParams<Row, number | null>
        ): React.JSX.Element | string | null => {
          const { row, value } = rowParams;
          switch (row.type) {
            case "empty_basket": {
              if (!disableEdit) {
                const {
                  copperGroup,
                  steelGrades,
                  dri,
                  mixId,
                  basketNumber,
                  id,
                } = row;
                return (
                  <EmptyBasketWeightCell
                    type="basket"
                    onSubmit={onRowChange}
                    id={id}
                    copperGroup={copperGroup}
                    steelGrades={steelGrades}
                    dri={dri}
                    mixId={mixId}
                    basketNumber={basketNumber}
                  />
                );
              } else {
                return null;
              }
            }
            case "empty_profile": {
              if (!disableEdit) {
                const {
                  copperGroup,
                  steelGrades,
                  dri,
                  mixId,
                  basketNumber,
                  id,
                  selectedSteelGrades,
                } = row;
                return (
                  <EmptyBasketWeightCell
                    type="profile"
                    onSubmit={onRowChange}
                    id={id}
                    copperGroup={copperGroup}
                    steelGrades={steelGrades}
                    dri={dri}
                    mixId={mixId}
                    basketNumber={basketNumber}
                    selectedSteelGrades={selectedSteelGrades}
                  />
                );
              } else {
                return null;
              }
            }
            case "total": {
              if (value) {
                return value.toFixed(0);
              }
              return null;
            }
            case "profile": {
              if (!disableEdit) {
                const {
                  id,
                  mixId,
                  copperGroup,
                  steelGrades,
                  dri,
                  type,
                  selectedSteelGrades,
                } = row;
                return (
                  <BasketWeightCell
                    type={type}
                    id={id}
                    mixId={mixId}
                    copperGroup={copperGroup}
                    steelGrades={steelGrades}
                    dri={dri}
                    basketNumber={1}
                    onSubmit={onRowChange}
                    value={value ?? null}
                    selectedSteelGrades={selectedSteelGrades}
                  />
                );
              } else {
                return value?.toFixed(0) ?? null;
              }
            }
            case "basket": {
              if (!disableEdit) {
                const {
                  id,
                  mixId,
                  copperGroup,
                  steelGrades,
                  dri,
                  type,
                  basketNumber,
                } = row;
                return (
                  <BasketWeightCell
                    type={type}
                    id={id}
                    mixId={mixId}
                    copperGroup={copperGroup}
                    steelGrades={steelGrades}
                    dri={dri}
                    basketNumber={TableBasketNumberSchema.parse(basketNumber)}
                    onSubmit={onRowChange}
                    value={value ?? null}
                  />
                );
              } else {
                return value?.toFixed(0) ?? null;
              }
            }
          }
        },
        valueGetter: (_, row, __, dataGridApi) => {
          switch (row.type) {
            case "basket":
            case "profile":
              return Object.values(row.materialMasses).reduce(
                (total, mass) => total + mass,
                0
              );
            case "total":
              return Object.values(
                dataGridApi.current
                  .getAllRowIds()
                  .map((rowId) => dataGridApi.current.getRow<Row>(rowId))
                  .filter(
                    (row: Row | null): row is ProfileRow | BasketRow =>
                      row !== null &&
                      row.type !== "total" &&
                      row.type !== "empty_basket"
                  )
                  .filter(({ mixId }) => mixId === row.mixId)
                  .reduce(
                    (totals, row) => {
                      const { materialMasses } = row;
                      return sumRecords(totals, materialMasses);
                    },
                    {} as Record<number, number>
                  )
              ).reduce((sum, mass) => sum + mass, 0);
          }
        },
        valueFormatter: (value: number) => {
          if (value !== undefined) {
            return value.toFixed(0);
          }
        },
        rowSpanValueGetter: () => null,
        cellClassName: (row) => {
          const baseClass = "grey-200 edge-column";
          const {
            row: { type },
          } = row;
          switch (type) {
            case "empty_basket": {
              return baseClass + " flex-start";
            }
            case "empty_profile": {
              return baseClass + " flex-start";
            }
            case "total": {
              return baseClass + " bottom-row align-center";
            }
            case "basket":
            case "profile":
              return baseClass + " space-between";
          }
        },
      },
      ...columnMaterials.map(
        (material): GridColDef<Row> => ({
          field: material.id.toString(),
          headerName: material.name,
          flex: 1,
          minWidth: 50,
          type: "number",
          valueGetter: (_, row, __, dataGridApi) => {
            switch (row.type) {
              case "basket":
              case "profile":
                return row.materialMasses[material.id] &&
                  row.materialMasses[material.id] !== 0
                  ? row.materialMasses[material.id]
                  : null;
              case "total": {
                const value = dataGridApi.current
                  .getAllRowIds()
                  .map((rowId) => dataGridApi.current.getRow<Row>(rowId))
                  .filter((row: Row | null): row is Row => row !== null)
                  .filter(
                    (
                      dataGridRow: Row
                    ): dataGridRow is ProfileRow | BasketRow => {
                      const { type } = dataGridRow;
                      switch (type) {
                        case "basket":
                        case "profile": {
                          return row.mixId === dataGridRow.mixId;
                        }
                        case "total": {
                          return false;
                        }
                      }
                      // This needs to be here to make eslint happy. Not sure why.
                      return false;
                    }
                  )
                  .reduce(
                    (totals, row) => {
                      const { materialMasses } = row;
                      return sumRecords(totals, materialMasses);
                    },
                    {} as Record<number, number>
                  )[material.id];

                return value ? value : null;
              }
            }
          },
          valueFormatter: (value: unknown) => {
            if (typeof value === "number") {
              return `${value.toFixed(0)}`;
            }
          },
          rowSpanValueGetter: () => null,
          editable: !disableEdit,
          valueSetter: (value, row) => {
            switch (row.type) {
              case "empty_basket":
              case "empty_profile":
              case "total": {
                return row;
              }
              case "profile":
              case "basket":
                if (value !== undefined && value !== null && value >= 0) {
                  return {
                    ...row,
                    materialMasses: {
                      ...row.materialMasses,
                      [material.id]: Number(value),
                    },
                  };
                } else {
                  return {
                    ...row,
                    materialMasses: {
                      ...row.materialMasses,
                      [material.id]: 0,
                    },
                  };
                }
            }
          },
          cellClassName: ({ row }) => {
            const { type } = row;
            switch (type) {
              case "total": {
                return "grey-50 bottom-row center";
              }
              case "empty_profile":
              case "empty_basket":
                return "";
              case "basket":
              case "profile": {
                return "center";
              }
            }
          },
        })
      ),
    ];

    return columns.map((column) => ({
      ...column,
      sortable: false,
      headerAlign: "center",
    }));
  }, [
    t,
    useUnitsFormatter,
    materials,
    materialYields,
    steelGradeColumnWidth,
    targetTappedMass,
    steelGrades,
    disableEdit,
    defaultDRIMaterialId,
    onRowChange,
    onSteelGradeClick,
    materialPrices,
  ]);
  return columns;
};

const makeBasketRow =
  (basketNumber: TableBasketNumber) =>
  (mix: NamedPlanMix): Omit<EmptyBasketRow, "id"> | Omit<BasketRow, "id"> => {
    const {
      baskets,
      dri,
      steel_grade_ids,
      copper_percent_max_tolerance,
      mix_id,
    } = mix;
    if (baskets[basketNumber]) {
      const { material_masses } = baskets[basketNumber]!;
      return {
        type: "basket",
        dri: dri,
        steelGrades: steel_grade_ids,
        materialMasses: material_masses,
        copperGroup: copper_percent_max_tolerance,
        mixId: mix_id,
        basketNumber,
      };
    } else {
      return {
        type: "empty_basket",
        mixId: mix_id,
        dri: dri,
        copperGroup: copper_percent_max_tolerance,
        steelGrades: steel_grade_ids,
        basketNumber,
      };
    }
  };

const makeBasketRow2 = makeBasketRow(2);
const makeBasketRow3 = makeBasketRow(3);

const makeProfileRow = (
  mix: NamedPlanMix,
  selectedSteelGrades: number[]
): Omit<ProfileRow, "id"> | Omit<EmptyProfileRow, "id"> => {
  if (mix.baskets[1]) {
    const materialMasses = mix.baskets[1] ? mix.baskets[1].material_masses : {};
    return {
      type: "profile",
      dri: mix.dri,
      copperGroup: mix.copper_percent_max_tolerance,
      steelGrades: mix.steel_grade_ids,
      materialMasses,
      mixId: mix.mix_id,
      selectedSteelGrades,
    };
  } else {
    return {
      type: "empty_profile",
      dri: mix.dri,
      copperGroup: mix.copper_percent_max_tolerance,
      steelGrades: mix.steel_grade_ids,
      materialMasses: {},
      mixId: mix.mix_id,
      selectedSteelGrades,
      basketNumber: 1,
    };
  }
};

const makeTotalRow = (mix: NamedPlanMix): Omit<TotalRow, "id"> => {
  return {
    type: "total",
    steelGrades: mix.steel_grade_ids,
    copperGroup: mix.copper_percent_max_tolerance,
    mixId: mix.mix_id,
    dri: mix.dri,
  };
};

const makeRows = (
  mixes: NamedPlanMix[],
  selectedSteelGrades: number[]
): Row[] => {
  return Object.values(mixes)
    .flatMap((mix) => {
      const row = [
        makeProfileRow(mix, selectedSteelGrades),
        makeBasketRow2(mix),
        makeBasketRow3(mix),
        makeTotalRow(mix),
      ];
      return row;
    })
    .map((row, index) => ({
      ...row,
      id: index,
    }));
};

const makeMix = (
  rows: Row[],
  mixes: Record<number, NamedPlanMix>
): NamedPlanMix => {
  const basketRows = rows.filter(
    (row: Row): row is BasketRow => row.type === "basket"
  );

  const profileRow = (() => {
    const profileRow = rows.find(
      (row: Row): row is ProfileRow => row.type === "profile"
    );
    const emptyProfileRow = rows.find(
      (row: Row): row is EmptyProfileRow => row.type === "empty_profile"
    );
    if (profileRow) {
      return profileRow;
    } else if (emptyProfileRow) {
      return emptyProfileRow;
    } else {
      throw new Error("Profile row missing on edit in Plan Table");
    }
  })();

  const mix = mixes[profileRow.mixId];

  if (!mix) {
    throw new Error("Mix not found for update in Plan Table");
  }

  return {
    ...mix,
    baskets: {
      1: {
        material_masses: profileRow.materialMasses,
        total_mass: Object.values(profileRow.materialMasses).reduce(
          (acc, mass) => acc + mass,
          0
        ),
      },

      ...basketRows
        .filter((basketRow) => basketRow.mixId === mix.mix_id)
        .reduce((baskets, basketRow) => {
          return {
            ...baskets,
            [basketRow.basketNumber]: {
              material_masses: basketRow.materialMasses,
              total_mass: Object.values(basketRow.materialMasses).reduce(
                (acc, mass) => acc + mass,
                0
              ),
            },
          };
        }, {}),
    },
  };
};

const getAllRows = (apiRef: React.MutableRefObject<GridApi>) => {
  return apiRef.current
    .getAllRowIds()
    .map((rowId) => apiRef.current.getRow<Row>(rowId))
    .filter((row: Row | null): row is Row => row !== null);
};

export const Table = ({
  disableEdit,
  mixes,
  materials,
  materialDecimalYields,
  targetTappedMass,
  steelGradeColumnWidth,
  defaultDRIMaterialId,
  steelGrades,
  apiRef,
  onEdit,
  onSteelGradeClick,
  selectedSteelGrades,
  materialPrices,
}: Props) => {
  const theme = useTheme();

  const rows = React.useMemo(() => {
    return makeRows(mixes, selectedSteelGrades);
  }, []);

  React.useEffect(() => {
    if (apiRef.current) {
      apiRef.current.setRows(makeRows(mixes, selectedSteelGrades));
    }
  }, [selectedSteelGrades, mixes]);

  const onRowChange = React.useCallback(
    (mixId: number) => {
      const newMix = makeMix(
        getAllRows(apiRef).filter((row) => row.mixId === mixId),
        Object.fromEntries(mixes.map((mix) => [mix.mix_id, mix]))
      );
      onEdit(newMix.mix_id, newMix.baskets);
    },
    [onEdit, mixes]
  );

  const columns = useMakeColumns(
    materials,
    materialDecimalYields,
    steelGradeColumnWidth,
    targetTappedMass,
    steelGrades,
    disableEdit,
    defaultDRIMaterialId,
    materialPrices,
    onRowChange,
    onSteelGradeClick
  );

  return (
    <DataGridPremium<Row>
      apiRef={apiRef}
      columns={columns}
      rows={rows}
      columnHeaderHeight={40}
      processRowUpdate={(newRow) => {
        /* 
            handleUpdateRow needs to happen AFTER this callback has returned the new row.
            By setting a timeout we can put its call to the the queue behind the 
            processRowUpdate prop callback's promise
          */
        setTimeout(() => onRowChange(newRow.mixId), 0);
        return newRow;
      }}
      unstable_rowSpanning
      isCellEditable={(params: GridCellParams<Row>) =>
        params.row.type !== "total"
      }
      sx={{
        width: "calc(100%)",
        [".align-center"]: {
          alignContent: "center",
        },
        [".space-between"]: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        [".flex-start"]: {
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        [".center"]: {
          display: "grid",
          justifyContent: "center",
          alignContent: "center",
        },
        [".warning-light"]: {
          backgroundColor: theme.palette.warning.light,
        },
        [".grey-200"]: {
          backgroundColor: theme.palette.grey[200],
        },
        [".grey-50"]: {
          backgroundColor: theme.palette.grey[50],
        },
        [".bottom-row"]: {
          borderBottom: `1px solid ${theme.palette.secondary.dark}`,
        },
        [".edge-column"]: {
          borderRight: `1px solid ${theme.palette.secondary.dark}`,
        },
        [".MuiDataGrid-columnHeader"]: {
          textAlign: "center",
          padding: 0,
          borderBottom: `1px solid ${theme.palette.secondary.dark} !important`,
        },
        [".MuiDataGrid-columnHeaderTitle"]: {
          fontSize: theme.typography.body1.fontSize,
        },
        [".MuiDataGrid-cell"]: {
          fontFamily: theme.typography.body1Mono,
        },
        ["input::-webkit-outer-spin-button, input::-webkit-inner-spin-button"]:
          {
            "-webkit-appearance": "none",
          },
      }}
    />
  );
};
