import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export const isFetchBaseQueryError = (
  error: unknown
): error is FetchBaseQueryError => {
  return typeof error === "object" && error !== null && "status" in error;
};

export const createMapping = <Data, Key extends string | number>(
  items: Data[],
  key: (item: Data) => Key
): { [key in Key]: Data } => {
  return Object.fromEntries(items.map((item) => [key(item), item])) as {
    [key in Key]: Data;
  };
};

export const createNestedMapping = <Data,>(
  items: Data[],
  outerKey: (item: Data) => number,
  innerKey: (item: Data) => number
) => {
  const result: { [outer: number]: { [inner: number]: Data } } = {};

  for (const item of items) {
    const itemOuterKey = outerKey(item);
    const itemInnerKey = innerKey(item);

    if (!(itemOuterKey in result)) {
      result[itemOuterKey] = {};
    }
    result[itemOuterKey]![itemInnerKey] = item;
  }

  return result;
};

export type ElementName =
  | "copper"
  | "chromium"
  | "molybdenum"
  | "nickel"
  | "tin";

export type Variability = "low" | "medium" | "high";

export const elementVariability = (
  element: ElementName,
  scale: number
): Variability | "none" => {
  const lowVariability: Record<ElementName, number> = {
    copper: 0.02,
    chromium: 0.02,
    molybdenum: 0.002,
    nickel: 0.02,
    tin: 0.002,
  };
  const mediumVariability: Record<ElementName, number> = {
    copper: 0.1,
    chromium: 0.1,
    molybdenum: 0.01,
    nickel: 0.1,
    tin: 0.01,
  };

  if (scale <= 0) {
    return "none";
  } else if (scale < lowVariability[element]) {
    return "low";
  } else if (scale < mediumVariability[element]) {
    return "medium";
  } else {
    return "high";
  }
};

export const groupBy = <T, A>(
  items: T[],
  key: (item: T) => number,
  aggregate: (items: T[]) => A
): { [key: number]: A } => {
  const groups: { [key: number]: T[] } = {};

  items.forEach((item) => {
    const itemKey = key(item);

    if (!(itemKey in groups)) {
      groups[itemKey] = [];
    }
    groups[itemKey]!.push(item);
  });

  return Object.fromEntries(
    Object.entries(groups).map((pair) => [pair[0], aggregate(pair[1])])
  );
};

export const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let result = reader.result;
      if (typeof result === "string") {
        result = result.split(",")[1]!;
      }
      return resolve(result);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const scaleToHeats = (
  value: number | null | undefined,
  targetNumHeats: number | null | undefined,
  originalNumHeats: number | null | undefined
) =>
  value == null || targetNumHeats == null || originalNumHeats == null
    ? undefined
    : originalNumHeats > 0.0
    ? (value * targetNumHeats) / originalNumHeats
    : 0.0;

export type EntityMapping<T> = {
  byIndex: T[];
  byId: Record<number, T>;
  byName: Record<string, T>;
};

/**
 * Split an array's items based on whether the predicate is true for each one.
 */
export const partition = <T,>(
  predicate: (item: T) => boolean,
  items: T[]
): [T[], T[]] => {
  return [items.filter(predicate), items.filter((item) => !predicate(item))];
};

export const sorted = <T, K extends number | boolean | string | number[]>(
  items: T[],
  key: (item: T) => K
): T[] => {
  return [...items].sort((left, right) => {
    const leftKey: K = key(left);
    const rightKey: K = key(right);

    if (typeof leftKey === "string" && typeof rightKey === "string") {
      return leftKey.localeCompare(rightKey);
    } else {
      return leftKey < rightKey ? -1 : leftKey > rightKey ? 1 : 0;
    }
  });
};

/**
 * This type will compare the union `U` and the string array `Arr`.
 * If `Arr` and `U` are not exact matches, TypeScript will emit an error.
 */
export type ExactStringUnionArray<
  Arr extends readonly string[],
  U extends string,
> =
  // Check that U is a subset of Arr[number]
  [U] extends [Arr[number]]
    ? // Then also check that Arr[number] is a subset of U
      [Arr[number]] extends [U]
      ? true
      : "ERROR: Array has a string not in type"
    : "ERROR: Type has a string not in Array";
