import { mapLoaded } from "models/loaded";
import { Loaded, liftLoadedState } from "models/loaded";
import { backendApi } from "store/api/enhancedApi";
import { useTenant } from "hooks/settings";
import {
  ProductionPlan,
  useProductGroups,
  useProductGroupsId,
  useSteelGradesId,
} from "../provider";

export type EstimateProductionCallback = [
  Loaded<
    (heats: number, type: "productGroups" | "steelGrades") => Promise<void>
  >,
  { running: boolean },
];

export const useEstimateProduction = (
  setProduction: (
    update: (productionPlan: ProductionPlan) => ProductionPlan
  ) => void
): EstimateProductionCallback => {
  const tenant = useTenant();
  const productGroupsId = useProductGroupsId();
  const steelGradesId = useSteelGradesId();
  const productGroups = useProductGroups();

  const [estimateProductGroups, { isLoading: productGroupsRunning }] =
    backendApi.endpoints.getTypicalProductGroupPlan.useLazyQuery();
  const [estimateSteelGrades, { isLoading: steelGradesRunning }] =
    backendApi.endpoints.getTypicalSteelGradePlan.useLazyQuery();

  return [
    mapLoaded(
      liftLoadedState({ productGroups, productGroupsId, steelGradesId }),
      ({
        productGroups: loadedProductGroups,
        productGroupsId: loadedProductGroupsId,
        steelGradesId: loadedSteelGradesId,
      }) => {
        return async (heats, type) => {
          if (type === "productGroups" && loadedProductGroupsId != null) {
            return estimateProductGroups({
              tenantName: tenant,
              numHeats: heats,
              productGroupingId: loadedProductGroupsId,
            })
              .unwrap()
              .then(({ items }) => {
                const mapping = Object.fromEntries(
                  items.map((item) => [item.product_group_id, item])
                );
                return setProduction(() => ({
                  type: "productGroup",
                  items: loadedProductGroups.byIndex.map(
                    (group) =>
                      mapping[group.id] ?? {
                        product_group_id: group.id,
                        num_heats: 0,
                      }
                  ),
                }));
              });
          } else {
            return estimateSteelGrades({
              tenantName: tenant,
              numHeats: heats,
              steelGradeSetId: loadedSteelGradesId,
            })
              .unwrap()
              .then(({ items }) =>
                setProduction(() => ({ type: "steelGrade", items }))
              );
          }
        };
      }
    ),
    { running: productGroupsRunning || steelGradesRunning },
  ];
};
