import { useTenantTranslation } from "hooks/formatters";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { useState } from "react";
import { usePlan } from "contexts/plan";
import { liftLoadedState } from "models/loaded";
import { ExportPlanModal } from "./modal";
import { Modal as EmailPlanModal } from "../emailPlan/Modal";
import { Modal as EditAndSendPlanModal } from "../editAndSendPlan/Modal";
import { LoadingButton } from "@mui/lab";
import { useDeployProcess } from "hooks/useDeployProcess";
import { useChemistryGroups } from "contexts/search/provider";
import { Tooltip } from "@mui/material";
import { usePlanId } from "components/common/boundary/PlanId";
import { Modal } from "../messagePlan/modal";
import { useIsDeployablePeriod } from "hooks/periodIndex.tsx";
import { DisableHotkeysInChildren } from "contexts/hotkeys";
import { SearchChemistryGroups } from "contexts/search/context";
import { Plan } from "src/store/api/generatedApi";

type DeployModalProps = {
  handleClose: () => void;
  plan: Plan;
  chemistryGroups: SearchChemistryGroups;
  planId: number;
};

const DeployModal = ({
  handleClose,
  plan,
  chemistryGroups,
  planId,
}: DeployModalProps) => {
  const deployProcess = useDeployProcess();
  switch (deployProcess) {
    case "sync_and_sms": {
      return (
        <ExportPlanModal
          open
          close={handleClose}
          plan={plan}
          chemistryGroups={chemistryGroups}
        />
      );
    }
    case "email": {
      return <EmailPlanModal open doClose={handleClose} />;
    }
    case "edit_and_send": {
      return <EditAndSendPlanModal open doClose={handleClose} />;
    }
    case "message": {
      return <Modal open doClose={handleClose} planId={planId} />;
    }
    case "none": {
      return null;
    }
  }
};

export const ExportPlan = () => {
  const { t } = useTenantTranslation();
  const [open, setOpen] = useState(false);

  const { plan } = usePlan(usePlanId());
  const chemistryGroups = useChemistryGroups();
  const isDeployablePeriod = useIsDeployablePeriod();

  const dependencies = liftLoadedState({
    plan,
    chemistryGroups,
  });

  const buttonDisabled = !isDeployablePeriod;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {buttonDisabled ? (
        <Tooltip title={t("deployMixesOnly")}>
          <span>
            <LoadingButton
              variant="contained"
              startIcon={<UpgradeIcon />}
              loading={dependencies.status !== "success"}
              disabled
            >
              {t("deploy")}
            </LoadingButton>
          </span>
        </Tooltip>
      ) : (
        <LoadingButton
          variant="contained"
          startIcon={<UpgradeIcon />}
          onClick={handleOpen}
          loading={dependencies.status !== "success"}
        >
          {t("deploy")}
        </LoadingButton>
      )}
      {open && dependencies.status === "success" ? (
        <DisableHotkeysInChildren>
          <DeployModal
            handleClose={handleClose}
            planId={dependencies.data.plan.id}
            plan={dependencies.data.plan}
            chemistryGroups={dependencies.data.chemistryGroups}
          />
        </DisableHotkeysInChildren>
      ) : null}
    </>
  );
};
