import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Permission, useInviteUsersMutation } from "src/store/api/generatedApi";
import { isFetchBaseQueryError } from "helpers";
import { PERMISSION_VALUES } from "components/users/permissions.ts";
import { v4 as uuidv4 } from "uuid";

type EmailEntry = {
  id: string;
  email: string;
};

export const InviteUser = ({
  tenant,
  isOpen,
  onClose,
  onError,
}: {
  tenant: string;
  isOpen: boolean;
  onClose: () => void;
  onError: (message: string) => void;
}) => {
  const [emails, setEmails] = useState<EmailEntry[]>([
    { id: uuidv4(), email: "" },
  ]);
  const [permission, setPermission] = useState<Permission>("read");
  const [inviteUsers] = useInviteUsersMutation();

  const handleAddUser = () => {
    setEmails([...emails, { id: uuidv4(), email: "" }]);
  };

  const handleRemoveUser = (index: number) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };

  const handleEmailChange = (index: number, email: string) => {
    const updatedEmails = [...emails];
    if (updatedEmails[index]) {
      updatedEmails[index]!.email = email;
    }
    setEmails(updatedEmails);
  };

  const handlePermissionChange = (event: SelectChangeEvent) => {
    setPermission(event.target.value as Permission);
  };

  const handleInviteUsers = async () => {
    await inviteUsers({
      tenantName: tenant,
      userInvite: {
        emails: [...emails.map((entry) => entry.email)],
        permission: permission,
      },
    })
      .unwrap()
      .then(() => {
        setEmails([{ id: uuidv4(), email: "" } as EmailEntry]);
        onClose();
      })
      .catch((err) => {
        if (isFetchBaseQueryError(err)) {
          onError(`Could not invite users (status code: ${err.status})`);
        }
      });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Invite Users</DialogTitle>
      <DialogContent sx={{ minWidth: "500px" }}>
        <Stack sx={{ m: 1, display: "flex", gap: 1 }}>
          <InputLabel id="permission-select-label">Permission</InputLabel>
          <Select
            labelId="permission-select-label"
            id="permission-select"
            value={permission}
            onChange={handlePermissionChange}
            label="Permission"
            sx={{ width: "150px" }}
          >
            {PERMISSION_VALUES.map((permission) => (
              <MenuItem key={permission} value={permission}>
                {permission}
              </MenuItem>
            ))}
          </Select>

          <InputLabel>Emails</InputLabel>
          {emails.map((entry, index) => (
            <Stack key={entry.id} direction="row">
              <TextField
                label="Email Address"
                variant="outlined"
                fullWidth
                value={entry.email}
                onChange={(e) => handleEmailChange(index, e.target.value)}
                sx={{ m: 1 }}
              />
              <IconButton
                onClick={() => handleRemoveUser(index)}
                disabled={index === 0 && emails.length === 1}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          ))}
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAddUser}
            sx={{
              m: 1,
              textTransform: "none",
              borderRadius: 0,
              width: "150px",
            }}
          >
            Add another
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => void handleInviteUsers()}
          sx={{ textTransform: "none", borderRadius: 0 }}
        >
          Invite Users
        </Button>
      </DialogActions>
    </Dialog>
  );
};
