import { useTenantTranslation } from "hooks/formatters";
import { Queue } from "@mui/icons-material";
import { usePlanId } from "hooks/search";
import { DisableHotkeysInChildren } from "contexts/hotkeys";
import { BackupModal } from "./backupModal";
import { liftLoadedState } from "models/loaded";
import { useState } from "react";
import { useBackups } from "./useBackups";
import { Button } from "@mui/material";

export const BackupButton = () => {
  const { t } = useTenantTranslation();
  const planId = usePlanId();
  const backups = useBackups(planId);

  const dependencies = liftLoadedState({ backups });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {dependencies.status !== "success" ||
      dependencies.data.backups.backups.length === 0 ? null : (
        <Button variant="contained" startIcon={<Queue />} onClick={handleOpen}>
          {t("backups")}
        </Button>
      )}

      {open && dependencies.status === "success" ? (
        <DisableHotkeysInChildren>
          <BackupModal
            open={open}
            close={handleClose}
            backups={dependencies.data.backups.backups}
          />
        </DisableHotkeysInChildren>
      ) : null}
    </>
  );
};
