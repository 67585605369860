import { createSlice } from "@reduxjs/toolkit";
import { loadLanguage } from "../persistence";
import { Permission } from "store/api/generatedApi.tsx";

export type TenantPermissions = {
  [tenant_name: string]: Permission[];
};

export type SettingsState = {
  locale: string;
  displayName: string;
  userUid: string | null;
  email: string | null;
  tenantPermissions: TenantPermissions;
  isAdmin: boolean;
};

export type AuthUpdate = {
  tenantPermissions: TenantPermissions;
  isAdmin: boolean;
  userUid: string;
  displayName: string;
  email: string;
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    locale: loadLanguage() ?? navigator.language,
    tenantPermissions: {},
    displayName: "",
  } as SettingsState,
  reducers: {
    languageSelected: (state, action: { payload: string }) => {
      state.locale = action.payload;
    },
    authUpdate: (state, action: { payload: AuthUpdate }) => {
      state.tenantPermissions = action.payload.tenantPermissions;
      state.isAdmin = action.payload.isAdmin;
      state.userUid = action.payload.userUid;
      state.displayName = action.payload.displayName;
      state.email = action.payload.email;
    },
  },
});

export const { languageSelected, authUpdate } = settingsSlice.actions;
