import { LoadRead } from "src/store/api/generatedApi";
import { typeSafeObjectEntries } from "src/utils/typeSafeObjectEntries";

export const refreshMS = 5000;

export const getDRIMass = (
  materialMasses: Record<number, number>,
  materialYieldsAsDecimal: Record<number, number>,
  targetTappedMass: number,
  driYieldAsDecimal: number
) => {
  if (driYieldAsDecimal < 0 || driYieldAsDecimal > 1) {
    throw new Error("DRI yield not gte 0 and lte 1");
  }
  if (
    Object.values(materialYieldsAsDecimal).some(
      (materialYield) => materialYield < 0 || materialYield > 1
    )
  ) {
    throw new Error("Material yield not gte 0 and lte 1");
  }
  return (
    (1 / driYieldAsDecimal) *
    (targetTappedMass -
      typeSafeObjectEntries(materialMasses).reduce(
        (total, [materialId, mass]) =>
          total + mass * materialYieldsAsDecimal[materialId]!,
        0
      ))
  );
};

export const isActive = (load: LoadRead) => {
  return (
    !isComplete(load) &&
    Object.values(load.baskets).some(
      (basket) => basket.assigned_to_uid !== null
    )
  );
};

export const isPending = (load: LoadRead) => {
  return (
    !isComplete(load) &&
    Object.values(load.baskets).every(
      (basket) => basket.assigned_to_uid === null
    )
  );
};

export const isComplete = (load: LoadRead) => {
  return Object.values(load.baskets).every(
    (basket) => basket.status == "filled"
  );
};

export const getLoadVariant = ({
  planId,
  currentPlanId,
  backupPlanId,
  nextPlanId,
}: {
  planId: number;
  currentPlanId: number;
  backupPlanId: number;
  nextPlanId: number | null;
}): "new" | "backup" | "out_of_date" | "next" => {
  if (planId === backupPlanId) {
    return "backup";
  } else if (planId === nextPlanId) {
    return "next";
  } else if (planId !== currentPlanId) {
    return "out_of_date";
  } else {
    return "new";
  }
};
