import { useTranslation, UseTranslationResponse } from "react-i18next";
import { Namespace, KeyPrefix } from "i18next";
import { useNumberSerialiser } from "./serialisers/numbers";
import { useTenant } from "./settings";
import { useMemo } from "react";
import { Period } from "./periodIndex";
import { useTenantData } from "contexts/tenant";
import { UnitType } from "src/store/api/generatedApi";

export const useTenantTranslation = (): UseTranslationResponse<
  Namespace,
  KeyPrefix<Namespace>
> => {
  const tenant = useTenant();

  return useTranslation([tenant, "translation"]);
};

export const usePeriodTabTranslation = () => {
  const { t } = useTenantTranslation();

  return (period: Period) => {
    switch (period) {
      case 1: {
        return t("schedule");
      }
      default: {
        return `${t("forecastPastParticiple")} ${period - 1}`;
      }
    }
  };
};

export const useUnitsFormatter = (bracketed: boolean) => {
  const { units: unitSpecs } = useTenantData();

  return useMemo(() => {
    return (unitType: UnitType): string => {
      const units = unitSpecs.find((spec) => spec.unit_type === unitType)
        ?.units;
      if (units === undefined) {
        return "";
      }
      if (bracketed) {
        return `\xa0(${units})`;
      } else {
        return `\xa0${units}`;
      }
    };
  }, [unitSpecs, bracketed]);
};

export const useFormattedTotalHeats = (
  totalHeats: number,
  producedMass: number
) => {
  const { typical_heats_per_day: heatsPerDay } = useTenantData();
  const { t } = useTenantTranslation();
  const { format } = useNumberSerialiser({ decimalPlaces: 1 });
  const units = useUnitsFormatter(false);

  const days = format(totalHeats / heatsPerDay);
  const daysTranslation = days === format(1.0) ? t("day") : t("days");

  return `~${days} ${daysTranslation} (${format(producedMass, {
    decimalPlaces: 0,
  })}${units("mass")})`;
};
