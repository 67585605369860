import { Box, Chip, SxProps, Theme, useTheme } from "@mui/material";

type Props = {
  name: string;
  variant: "success" | "warning";
  sx?: SxProps;
};

const defaultSx = {};

const useColor = (
  variant: "success" | "warning"
): Theme["palette"]["success"]["main"] => {
  const theme = useTheme();
  switch (variant) {
    case "success":
      return theme.palette.success.main;
    case "warning":
      return theme.palette.warning.main;
  }
};

export const CraneChip = ({ name, variant, sx = defaultSx }: Props) => {
  const color = useColor(variant);

  return (
    <Chip
      sx={sx}
      label={name}
      size="small"
      icon={
        <Box
          sx={{
            backgroundColor: color,
            padding: 0.5,
            borderRadius: "50%",
          }}
        />
      }
    />
  );
};
