import { ContentCopy, ContentPaste } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import {
  SearchProductGroups,
  SearchSteelGrades,
} from "contexts/search/context";
import { ProductionPlan } from "contexts/search/provider";
import { useNotifyStatus } from "contexts/status";
import { useTenantTranslation } from "hooks/formatters";
import { Maybe } from "models/maybe";

export type Setter<T> = (update: (current: T) => T) => void;

const copyProduction = (
  productionPlan: ProductionPlan,
  productGroups: SearchProductGroups,
  steelGrades: SearchSteelGrades
): string => {
  const items =
    productionPlan.type === "steelGrade"
      ? productionPlan.items.map((item) => ({
          name: steelGrades.byId[item.steel_grade_id]!.name,
          num_heats: item.num_heats,
        }))
      : productionPlan.items.map((item) => ({
          name: productGroups.byId[item.product_group_id]!.name,
          num_heats: item.num_heats,
        }));
  return JSON.stringify({ ...productionPlan, items });
};

const pasteProduction = (
  productionPlan: string,
  productGroups: SearchProductGroups,
  steelGrades: SearchSteelGrades
): Maybe<ProductionPlan> => {
  try {
    const plan = JSON.parse(productionPlan) as {
      type: "steelGrade" | "productGroup";
      items: { name: string; num_heats: number }[];
    };
    return {
      present: true,
      value:
        plan.type === "steelGrade"
          ? {
              type: "steelGrade",
              items: plan.items.map((item) => ({
                steel_grade_id: steelGrades.byName[item.name]!.id,
                num_heats: item.num_heats,
              })),
            }
          : {
              type: "productGroup",
              items: plan.items.map((item) => ({
                product_group_id: productGroups.byName[item.name]!.id,
                num_heats: item.num_heats,
              })),
            },
    };
  } catch {
    return { present: false };
  }
};

export const CopyProductionButton = ({
  productionPlan,
  productGroups,
  steelGrades,
}: {
  productionPlan: ProductionPlan;
  productGroups: SearchProductGroups;
  steelGrades: SearchSteelGrades;
}) => {
  const { t } = useTenantTranslation();
  const notifyStatus = useNotifyStatus();

  return (
    <Box>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<ContentCopy />}
        onClick={() =>
          navigator.clipboard
            .writeText(
              copyProduction(productionPlan, productGroups, steelGrades)
            )
            .then(() =>
              notifyStatus({
                type: "success",
                text: t("productionCopiedToClipboard"),
              })
            )
        }
      >
        {t("copyVerb")}
      </Button>
    </Box>
  );
};

export const PasteProductionButton = ({
  setProductionPlan,
  productGroups,
  steelGrades,
}: {
  setProductionPlan: Setter<ProductionPlan>;
  productGroups: SearchProductGroups;
  steelGrades: SearchSteelGrades;
}) => {
  const { t } = useTenantTranslation();
  const notifyStatus = useNotifyStatus();

  return (
    <Box>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<ContentPaste />}
        onClick={() =>
          navigator.clipboard
            .readText()
            .then((text) => {
              const plan = pasteProduction(text, productGroups, steelGrades);

              if (plan.present && plan.value.items.length > 0) {
                setProductionPlan(() => plan.value);
              } else {
                throw new Error();
              }
            })
            .catch(() =>
              notifyStatus({ type: "error", text: t("failedToReadProduction") })
            )
        }
      >
        {t("pasteVerb")}
      </Button>
    </Box>
  );
};
