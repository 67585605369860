import { ArrowForward } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";

import { useTenantTranslation } from "hooks/formatters";

import {
  useMaterialPhysics,
  useMaterials,
  useMiscParams,
  usePhysicalParameters,
  useProductionSchedule,
  useSteelGrades,
} from "contexts/search/provider";

import { createPlanMixUpdates } from "../../../crane/SupervisorView";
import { PlanId } from "components/common/boundary/PlanId";
import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";
import {
  generatedApi,
  MaterialPhysics,
  MaterialRead,
  NamedPlanMix,
  NamedPlanMixes,
  PhysicalParameters,
  useGetMaterialPricesForPlanQuery,
  useGetPlanMixesQuery,
  useUpdatePlanMixesMutation,
} from "src/store/api/generatedApi";
import { useTenant } from "hooks/settings";
import { useCallback, useMemo, useState } from "react";
import { SteelGrade } from "contexts/search/context";
import { EditableTable } from "components/common/plan/EditableTable";
import { useSearchId } from "hooks/search";
import { steelGradeSearchSlice } from "src/store/slices/steelGradeSearchSlice";
import { useAppDispatch } from "src/store/store";

type ContentProps = {
  mixes: NamedPlanMixes;
  materials: MaterialRead[];
  materialPhysics: MaterialPhysics[];
  materialPrices: Record<number, number | null>;
  physicalParameters: PhysicalParameters;
  steelGradeColumnWidth: number;
  steelGrades: Record<number, SteelGrade>;
  planId: PlanId;
  setPlanId: (planId: PlanId) => void;
  toNextState: () => void;
};

export const ApprovePlanContent = ({
  mixes,
  materials,
  materialPhysics,
  materialPrices,
  physicalParameters,
  steelGradeColumnWidth,
  steelGrades,
  planId,
  setPlanId,
  toNextState,
}: ContentProps) => {
  const tenantName = useTenant();
  const searchId = useSearchId();
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();

  const materialDecimalYields = useMemo(
    () =>
      typeSafeObjectFromEntries(
        Object.values(materialPhysics).map((material) => [
          material.material_id,
          material.yield_percent / 100,
        ])
      ),
    [materialPhysics]
  );
  const targetTappedMass = physicalParameters.target_tapped_mass_lower;
  const [doUpdatePlanMixesMutation] = useUpdatePlanMixesMutation();

  const [originalMixes, setOriginalMixes] = useState(mixes.mixes);

  const [getResult] = generatedApi.useLazyGetResultQuery();

  const handleOnSubmit = useCallback(
    async (editedMixes: NamedPlanMix[]) => {
      const { data: updated_plan, error } = await doUpdatePlanMixesMutation({
        tenantName,
        planId,
        planMixUpdates: {
          period: 1,
          mixes: createPlanMixUpdates(
            originalMixes,
            editedMixes,
            materialDecimalYields,
            targetTappedMass
          ),
          replacements: {},
        },
      });
      if (error === undefined && updated_plan !== undefined) {
        dispatch(
          steelGradeSearchSlice.actions.copy([planId, updated_plan.plan_id])
        );
        if (searchId !== null) {
          await getResult({ searchId, tenantName });
          setPlanId(updated_plan.plan_id as PlanId);
        }
        setOriginalMixes(updated_plan.mixes);
      }
    },
    [
      doUpdatePlanMixesMutation,
      tenantName,
      planId,
      originalMixes,
      materialDecimalYields,
      targetTappedMass,
      setPlanId,
      dispatch,
    ]
  );

  return (
    <Stack sx={{ minHeight: 0, display: "flex", gap: 1 }}>
      <Button
        sx={{ alignSelf: "end" }}
        onClick={toNextState}
        endIcon={<ArrowForward />}
      >
        {t("approveAndNext")}
      </Button>
      <EditableTable
        planId={planId}
        disableEdit={false}
        mixes={originalMixes}
        materials={materials}
        materialDecimalYields={materialDecimalYields}
        steelGradeColumnWidth={steelGradeColumnWidth}
        targetTappedMass={targetTappedMass}
        defaultDRIMaterialId={mixes.default_dri_id}
        steelGrades={steelGrades}
        onSubmit={handleOnSubmit}
        materialPrices={materialPrices}
      />
    </Stack>
  );
};

type Props = {
  toNextState: () => void;
  planId: PlanId;
  setPlanId: (planId: PlanId) => void;
};

export const ApprovePlan = ({ toNextState, planId, setPlanId }: Props) => {
  const [productionSchedule] = useProductionSchedule();
  const [miscParams] = useMiscParams();
  const [physicalParameters] = usePhysicalParameters();
  const materials = useMaterials();
  const materialPhysics = useMaterialPhysics();
  const steelGrades = useSteelGrades();
  const tenantName = useTenant();
  const planMixes = useGetPlanMixesQuery({ tenantName, planId, period: 1 });
  const materialPrices = useGetMaterialPricesForPlanQuery({
    tenantName,
    planId,
  });

  if (
    productionSchedule.status === "success" &&
    miscParams.status === "success" &&
    physicalParameters.status === "success" &&
    planMixes.data &&
    materialPrices.data &&
    materials.status === "success" &&
    materialPhysics.status === "success" &&
    steelGrades.status === "success"
  ) {
    return (
      <ApprovePlanContent
        mixes={planMixes.data}
        materials={materials.data.byIndex}
        materialPrices={materialPrices.data}
        materialPhysics={materialPhysics.data.byIndex}
        steelGradeColumnWidth={210}
        physicalParameters={physicalParameters.data}
        steelGrades={steelGrades.data.byId}
        planId={planId}
        setPlanId={setPlanId}
        toNextState={toNextState}
      />
    );
  } else {
    return null;
  }
};
