import { Autocomplete, SxProps, TextField } from "@mui/material";
import { useState } from "react";

/**
 * Generate a searchable multiselect box for strings.
 */

const defaultSx: SxProps = {};

type Props<T, Id extends number | string> = {
  sx?: SxProps;
  values: T[];
  options: T[];
  label: string;
  getOptionLabel: (option: T) => string;
  getOptionId: (option: T) => Id;
  disabled?: boolean;
  setValues: (newValues: T[]) => void;
  small?: boolean;
};

export const MultiSelect = <T, Id extends number | string>({
  options,
  values,
  label,
  setValues,
  getOptionLabel,
  disabled = false,
  small,
  sx = defaultSx,
}: Props<T, Id>) => {
  const [inputValues, setInputValues] = useState("");

  const handleChange = (newValues: T[]) => {
    setValues(newValues);
  };

  const handleInputChange = (newInputValues: string) => {
    setInputValues(newInputValues);
  };

  return (
    <Autocomplete
      sx={sx}
      disabled={disabled}
      options={options}
      value={values}
      onChange={(_, values) => handleChange(values)}
      inputValue={inputValues}
      onInputChange={(_, values) => handleInputChange(values)}
      multiple
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      renderOption={({ key, ...props }, option) => (
        <li key={key as string | number} {...props}>
          {getOptionLabel(option)}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label={label} />}
      size={small ? "small" : undefined}
    />
  );
};
